import React from "react";
import "./style.css";
import PageWrapper from "@components/PageWrapper";
import { Link } from "react-router-dom";

const NotFound = () => {
    return (
        <div className="not-found">
            <PageWrapper>
                <p className="subtitle">Oops</p>
                <h2>We are sorry</h2>
                <h3>
                    The content you are looking for is not available or does not
                    exist
                </h3>
                <hr />

                <Link to="/">Back to the dashboard</Link>
            </PageWrapper>
        </div>
    );
};

export default NotFound;
