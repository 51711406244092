import React from "react";
import "./style.css";
import PageWrapper from "@components/PageWrapper";
import Background from "@images/background.svg";
import { ReactComponent as Logo } from "@images/logo-full.svg";
import { ReactComponent as Ribbon } from "@images/ribbon.svg";
import { useSelector } from "react-redux";
import { useFirestoreConnect } from "react-redux-firebase";
import { format } from "date-fns";

const CertificateView = (props) => {
    const { courseName, timeValid } = props;

    const userId = useSelector((state) => state.firebase.auth.uid);
    useFirestoreConnect({ collection: "users", doc: userId });
    const user = useSelector((state) => state.firestore.data?.users?.[userId]);

    const timeValidFormatted = format(new Date(timeValid), "d MMMM yyyy");

    //const downloadCertification = () => {};

    return (
        <div className="certificate-view">
            <PageWrapper>
                <div
                    className="certificate-view__background"
                    style={{ backgroundImage: `url(${Background})` }}
                >
                    <div className="certificate-view__content">
                        <div className="certificate-view__content__logo">
                            <Logo />
                        </div>
                        <p className="subtitle">Certificate of completion</p>
                        <h4>{courseName}</h4>
                        <p className="subtitle">to</p>
                        <h4>
                            {user?.name} {user?.surname}
                        </h4>

                        <div className="certificate-view__content__ribbon">
                            <Ribbon />
                        </div>
                        <p className="subtitle">Valid until</p>
                        <p className="smaller">{timeValidFormatted}</p>
                    </div>
                </div>
                {/* <button className="button-secondary">Download</button> */}
            </PageWrapper>
        </div>
    );
};

export default CertificateView;
