import React from "react";
import "./style.css";
import timeFormat from "@utils/timeFormat";
import { ReactComponent as CertificateIcon } from "@images/certificate-icon.svg";
import classnames from "classnames";
import { useTranslation } from "react-i18next";

export const TimeProgressBar = ({ className, value, maxValue }) => {
    return (
        <div className={`progress-bar ${className}`}>
            <div className="progress-bar__wrapper">
                <div
                    className="progress-bar__bar"
                    style={{ width: `${(value / maxValue) * 100}%` }}
                />
            </div>
            <div className="progress-bar__time-left">{timeFormat(value)}</div>
            <div className="progress-bar__time-right">
                -{timeFormat(maxValue - value)}
            </div>
        </div>
    );
};

const ProgressBar = ({
    className = "",
    percentage,
    value,
    maxValue,
    showPercentage = true,
    showCertified = false,
    showValue = true,
    onCertifiedClick,
    isCoursePage,
}) => {
    const { t } = useTranslation();

    return (
        <div className={`progress-bar ${className}`}>
            <div className="progress-bar__wrapper">
                <div
                    className="progress-bar__bar"
                    style={{ width: `${showCertified ? 100 : percentage}%` }}
                />
            </div>
            <div className="progress-bar__info-row">
                {showCertified && (
                    <div
                        className="progress-bar__percentage"
                        onClick={onCertifiedClick}
                    >
                        <span className="progress-bar__percentage--certified">
                            <CertificateIcon className="progress-bar__percentage--certified__icon" />
                            {t('progressBar.certified')}
                        </span>
                    </div>
                )}
                {showPercentage && (
                    <div className="progress-bar__percentage">
                        <span className="progress-bar__percentage--percent">
                            {percentage}%
                        </span>
                        <span className="progress-bar__percentage--label">
                            {t('progressBar.completed')}
                        </span>
                    </div>
                )}
                {showValue &&
                    (isCoursePage ? (
                        <div
                            className={classnames("progress-bar__value", {
                                "progress-bar__value--certified": showCertified,
                            })}
                        >
                            {t('progressBar.step')} {value}
                            {maxValue ? ` ${t('progressBar.of')} ${maxValue}` : ""}
                        </div>
                    ) : (
                        <div
                            className={classnames("progress-bar__value", {
                                "progress-bar__value--certified": showCertified,
                            })}
                        >
                            {value}
                            {maxValue ? ` / ${maxValue}` : ""}
                        </div>
                    ))}
            </div>
        </div>
    );
};

export default ProgressBar;
