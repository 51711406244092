import React from "react";
import { useTranslation } from "react-i18next";
import "./style.css";
import cx from "classnames";
import { useSelector } from "react-redux";
import useGroupedCoursesList from "src/hooks/useGroupedCoursesList";

const CoursesProgressChart = ({ className }) => {
    const userId = useSelector((state) => state.firebase.auth.uid);
    const { inProgress, future, completed } = useGroupedCoursesList(userId);
    const { t } = useTranslation();

    const strokeW = 0.04;
    const innerRadius = 6;
    let inPr = inProgress?.length || 0;
    let compl = completed?.length || 0;
    let fut = future?.length || 0;
    let total = inPr + compl + fut;

    const r = 8;
    const inPrAng = -Math.PI - (inPr / total) * Math.PI;
    const complAng =
        -Math.PI - (inPr / total) * Math.PI * 2 - (compl / total) * Math.PI;
    const futAng = -Math.PI + (fut / total) * Math.PI;

    return (
        <div className={cx("courses-progress-chart", className)}>
            <svg height="20" width="20" viewBox="0 0 20 20">
                <circle
                    r="10"
                    cx="10"
                    cy="10"
                    fill="#fedeb8"
                    strokeWidth="0.1"
                />
                <circle
                    r="5"
                    cx="10"
                    cy="10"
                    fill="white"
                    stroke={"#FFB151"}
                    strokeWidth="10"
                    strokeDasharray={`${
                        ((compl + inPr) / total) * 31.415
                    } 31.415`}
                    transform="rotate(-90) translate(-20)"
                />
                <circle
                    r="5"
                    cx="10"
                    cy="10"
                    fill="white"
                    stroke={"#F86459"}
                    strokeWidth="10"
                    strokeDasharray={`${(inPr / total) * 31.415} 31.415`}
                    transform="rotate(-90) translate(-20)"
                />
                <circle
                    r={innerRadius + strokeW}
                    cx="10"
                    cy="10"
                    fill="white"
                    stroke="var(--red)"
                    strokeWidth={strokeW}
                />
                <circle
                    r={10 - strokeW}
                    cx="10"
                    cy="10"
                    fill="transparent"
                    stroke="var(--red)"
                    strokeWidth={strokeW}
                />

                <text
                    x={10 + r * Math.sin(inPrAng)}
                    y={10 + r * Math.cos(inPrAng)}
                    textAnchor="middle"
                    dominantBaseline="central"
                    className="chart-value"
                >
                    {inPr ? inPr : null}
                </text>
                <text
                    x={10 + r * Math.sin(complAng)}
                    y={10 + r * Math.cos(complAng)}
                    textAnchor="middle"
                    dominantBaseline="central"
                    className="chart-value"
                >
                    {compl ? compl : null}
                </text>
                <text
                    x={10 + r * Math.sin(futAng)}
                    y={10 + r * Math.cos(futAng)}
                    textAnchor="middle"
                    dominantBaseline="central"
                    className="chart-value inverse"
                >
                    {fut ? fut : null}
                </text>
            </svg>

            <ul className={cx("courses-progress-chart__legend")}>
                {inPr !== 0 && (
                    <li>
                        <span
                            className="dot"
                            style={{ backgroundColor: "#F86459" }}
                        />
                        {t('courses.inProgressText')} <em>({inPr})</em>
                    </li>
                )}
                {compl !== 0 && (
                    <li>
                        <span
                            className="dot"
                            style={{ backgroundColor: "#FFB151" }}
                        />
                        {t('courses.completedCoursesText')} <em>({compl})</em>
                    </li>
                )}
                {fut !== 0 && (
                    <li>
                        <span
                            className="dot"
                            style={{ backgroundColor: "#FFD39C" }}
                        />
                        {t('courses.toStartText')} <em>({fut})</em>
                    </li>
                )}
            </ul>
        </div>
    );
};

export default CoursesProgressChart;
