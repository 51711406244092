import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import { useFirestoreConnect } from "react-redux-firebase";

import { ScrollableSectionWithBadge } from "@components/SectionWithBadge";
import TopicsTile from "@components/TopicsTile";
import PageWrapper from "@components/PageWrapper";
import { ReactComponent as Pie } from "@images/pie.svg";
import AppSlice from "@store/app";
import { ReactComponent as HorizontalListIcon } from "@images/horizontal-list.svg";
import { ReactComponent as VerticalListIcon } from "@images/vertical-list.svg";

import "./style.css";
import useGroupedCoursesList from "@hooks/useGroupedCoursesList";

const Topics = () => {
    const userId = useSelector((state) => state.firebase.auth.uid);
    const dispatch = useDispatch();
    const { t } = useTranslation();

    const [verticalLayout, setVerticalLayout] = useState(false);

    useFirestoreConnect([
        {
            collection: "users",
            doc: userId,
            subcollections: [{ collection: "userCourses" }],
            storeAs: "myCourses",
        },
        { collection: "courses" },
    ]);

    let { inProgress, future, completed } = useGroupedCoursesList(userId) || {};

    if (inProgress) {
        inProgress = inProgress.filter(course => course.category !== 'science')
    }
    if (future) {
        future = future.filter(course => course.category !== 'science')
    }
    if (completed) {
        completed = completed.filter(course => course.category !== 'science')
    }

    const showProgress = () => {
        dispatch(
            AppSlice.actions.setModal({
                visible: true,
                content: { type: "coursesProgress" },
            })
        );
    };
    
    return (
        <div className="topics">
            <PageWrapper>
                <h1 className="topics__title">
                    {t("courses.pageHeader")}
                    <div>
                        {verticalLayout ? (
                            <HorizontalListIcon
                                onClick={() =>
                                    setVerticalLayout(!verticalLayout)
                                }
                            />
                        ) : (
                            <VerticalListIcon
                                onClick={() =>
                                    setVerticalLayout(!verticalLayout)
                                }
                            />
                        )}
                        <Pie
                            onClick={showProgress}
                            className="topics__title-icon"
                        />
                    </div>
                </h1>

                <p className="larger topics__description">
                    {t("courses.mainHeader")}
                </p>

                <hr />
                {inProgress?.length > 0 && (
                    <ScrollableSectionWithBadge
                        title={t("courses.onGoingCoursesText")}
                        badge={inProgress?.length}
                        vertical={verticalLayout}
                    >
                        {inProgress.map((course, i) => (
                            <TopicsTile
                                key={i}
                                topicId={course.id}
                                course={course}
                                vertical={verticalLayout}
                            />
                        ))}
                    </ScrollableSectionWithBadge>
                )}
                {future?.length > 0 && (
                    <ScrollableSectionWithBadge
                        title={t("courses.toStartCoursesText")}
                        badge={future?.length}
                        vertical={verticalLayout}
                    >
                        {future.map((course, i) => (
                            <TopicsTile
                                key={i}
                                topicId={course.id}
                                course={course}
                                vertical={verticalLayout}
                            />
                        ))}
                    </ScrollableSectionWithBadge>
                )}

                {completed?.length > 0 && (
                    <ScrollableSectionWithBadge
                        title={t("courses.completedCoursesText")}
                        badge={completed?.length}
                        noHr
                        vertical={verticalLayout}
                    >
                        {completed.map((course, i) => (
                            <TopicsTile
                                key={i}
                                topicId={course.id}
                                course={course}
                                vertical={verticalLayout}
                            />
                        ))}
                    </ScrollableSectionWithBadge>
                )}
            </PageWrapper>
        </div>
    );
};

export default Topics;
