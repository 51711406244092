const useGenerateAssertiveResults = () => {
  const scores = {};
  const percents = {};

  const fetchData = (survey) => {
    // Create a copy of survey data in order not to mutate survey objec when calculating results
    const dataCopy = JSON.parse(JSON.stringify(survey.data));

    if (survey) {
      const rankingQuestions = [];
      survey?.pages.forEach((page) => {
          // check if the first element is a QuestionRankingModel
          if (page.elements?.[0]) {
              rankingQuestions.push(page.elements?.[0]);
          }
      });

      //added values(passive, passive/agressive, agessive, assertive) to answered questions
      rankingQuestions.forEach(page => {
        if (page.choices) {
          page.choices.forEach(choice => {
            dataCopy[`${page.name}`].forEach((resultChoice, index) => {
              if(choice.value === resultChoice) {
                dataCopy[`${page.name}`][index] = choice.value;
              }
            })
          })
        }    
      })

      const scoreByIndex = [5, 3, 1, 0];
      let totalScore = 0;

      //build scores object
      Object.entries(dataCopy).forEach(question => {
        question[1].forEach((type, index) => { 
          scores[`${type}`] = scores[`${type}`] ? scores[`${type}`] + scoreByIndex[index] : 0 + scoreByIndex[index];
          totalScore += scoreByIndex[index];
        })
      })

      //build percents object
      for(let [key, value] of Object.entries(scores)) {
        percents[`${key}`] = Math.round(Number(value / totalScore) * 100);
      }

      return { scores, percents }
    }
  }

  return fetchData;

}

export default useGenerateAssertiveResults;