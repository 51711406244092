export default function getPodcastStats(podcast) {
    const time = podcast?.time || 0;
    const duration = podcast?.duration || 0;
    const isCompleted = !!podcast?.timeCompleted;
    const isStarted = !!podcast?.timeStarted;
    // const isInProgress = isStarted && !isCompleted;
    const isInProgress = isStarted && time > 0;

    const isFuture = !isStarted && !isCompleted;

    return {
        time,
        duration,
        isCompleted,
        isStarted,
        isInProgress,
        isFuture,
    };
}
