import React from 'react';
import { useTranslation } from 'react-i18next';
import { IoRemoveCircleOutline } from "react-icons/io5";
import { IoAddCircleOutline } from "react-icons/io5";
import './CustomProgressBar.css';

const CustomProgressBar = ({percent, title, text}) => {
  const { t } = useTranslation();

  const showText = () => {
    document.getElementById(`subtext-${title}`).classList.toggle('hide');
    document.getElementById(`hide-${title}`).classList.toggle('hide');
    document.getElementById(`discover-${title}`).classList.toggle('hide');
  }

  const hideText = () => {
    document.getElementById(`subtext-${title}`).classList.toggle('hide');
    document.getElementById(`hide-${title}`).classList.toggle('hide');
    document.getElementById(`discover-${title}`).classList.toggle('hide');
  }

  return (
    <div className="progress-bar-container">
      <h2>{title}</h2>
      <div className="bar-container">
        <div className="grey-bar">
          <div className="orange-bar" style={{'width': `${percent}%`}}>
            <p>{percent}%</p>
          </div>
        </div>
        <p id={`hide-${title}`} className="center" onClick={hideText}><IoRemoveCircleOutline /> <span>{t('common.hide')}&nbsp;</span></p>
        <p id={`discover-${title}`} className="center hide" onClick={showText}><IoAddCircleOutline /> <span>{t('common.show')}</span></p>
      </div>
      <p id={`subtext-${title}`}>{text}</p>
    </div>
  )
}

export default CustomProgressBar
