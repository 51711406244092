import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { ReactComponent as ModalEmail } from '@assets/svg/ModalMail.svg';
import { ReactComponent as ModalSend } from '@assets/svg/ModalSend.svg';
import { ReactComponent as ModalAttachment } from '@assets/svg/ModalAttachment.svg';
import { IoClose } from "react-icons/io5";
import { useTranslation } from 'react-i18next';
import firebase from 'firebase';
import './EmailModal.css';

const isEmailValid = (email) => {
  const emailRegEx = new RegExp('.*@[a-z, 0-9]*..{1,}', 'gim');
  return(email.match(emailRegEx));
}

const EmailModal = ({ inviteFriend, shareResults, onClose }) => {
  const userId = useSelector(state => state.firebase.auth.uid);
  const userEmail = useSelector(state => state.firebase.auth.email);

  const { t, i18n } = useTranslation();

  const [loading, setLoading] = useState(false);
  const [emailStatus, setEmailStatus] = useState(false);

  const [friendEmail, setFriendEmail] = useState({
    value: '',
    sentStatus: false,
    valid: false
  })

  const handleInputChange = (e) => {
    setFriendEmail({
      ...friendEmail,
      value: e.target.value,
      valid: isEmailValid(e.target.value)
    });
  }

  const shareAppLink = firebase.functions().httpsCallable('utils-shareAppLink');
  const doShareAppLink = () => {
    setLoading(true);
    shareAppLink(friendEmail.value).then(res => {
      setFriendEmail({
        ...friendEmail,
        value: '',
        sentStatus: res.data.friendEmailStatusSent
      });
      setLoading(false);
    });
  }

  const generatePdf = firebase.functions().httpsCallable('generatePdfPuppeteer-generatePdfPuppeteer');
    
  const doGeneratePdf = () => {
    setLoading(true);
    try {
      generatePdf({
        userId: userId,
        pathToTemplate: 'cStyleResultsTemplate/cStyleResultsTemplate',
        email: userEmail,
        language: i18n.language
      }).then(res => {
        setEmailStatus(res.data.emailSentStatus);
        setLoading(false);
      })
    } catch (err) {
      setEmailStatus(false);
      setLoading(false);
    }
  }

  return (
    <div className="invite-modal-conatiner">
      <div className="closeModal">
        <IoClose  onClick={onClose} />
      </div>
      {
        loading 
          ?<div className="loading-container">
            <div className="loading-div"></div>
            <span>processing...</span>
          </div>

          :<div className="modal-body">
            <div className="icon-container">
              {
                shareResults 
                  ? <ModalAttachment />
                  : <ModalEmail />
              }
            </div>
            {
              shareResults
                ? <div className="email-text-container">
                    {
                      emailStatus
                        ?<div>
                          <h1>Your results were successfully sent</h1>
                          <button onClick={onClose}>Close</button>
                        </div>

                        :<div>
                          <h1>Your results will be sent to {userEmail}</h1>
                          <button onClick={ doGeneratePdf }>
                            <span>Send</span>
                            <ModalSend />
                          </button>
                        </div>
                    }
                  </div>

                : <div className="email-text-container">
                    {
                      friendEmail.sentStatus
                        ?<div>
                          <h1>Invite sent successfully</h1>
                          <button onClick={onClose}>Close</button>
                        </div>

                        :<div>
                          <h1>Text telling you to invite friends</h1>
                          <div className="input-container">
                            <input type="text" onChange={ handleInputChange } />
                            {
                              friendEmail.valid 
                                ?<ModalSend onClick={ doShareAppLink } />
                                :<ModalSend style={{opacity: '.2'}} />
                            }
                          </div>
                        </div>
                    }
                  </div>
            }
          </div>
      }
    </div>
  )
}

export default EmailModal;
