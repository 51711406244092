export const onUpdatePageCssClasses = (options) => {
    var classes = options.cssValue;

    classes.title = "custom-sv-title";
    classes.question.title = "custom-sv-question-title";
    classes.html = "custom-html-elements";
    classes.footer = "custom-sv-footer";
    classes.header = "custom-sv-header";
    classes.navigation.next = "custom-sv-next";
    classes.navigation.prev = "custom-sv-prev";
    classes.navigation.complete = "custom-sv-complete";

    const hasSwipeQuestions = options.currentPage.elements.some(
        (el) => el.getType() === "radiogroup" && el.behavior === "swipe"
    );
    if (hasSwipeQuestions) {
        classes.page.root = "sv_p_root sv_p_swipe";
    } else {
        classes.page.root = "sv_p_root";
    }
};

export const onUpdateQuestionCssClasses = (survey, options) => {
    var classes = options.cssClasses;

    if (options.question.getType() === "radiogroup") {
        classes.label = "custom-sv-radiogroup";
        classes.item = "custom-sv-radiogroup-item";
    } 
};
