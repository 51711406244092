import React from 'react';
import { IoCloseSharp } from "react-icons/io5";
import { useDispatch } from 'react-redux';
import SiSlice from '@store/si';
import './CustomModal.css';
import JsxParser from 'react-jsx-parser';

const CustomModal = ({content}) => {
  const dispatch = useDispatch();

  return (
    <div className="custom-modal">
      <div className="modal-text">
        <button 
          onClick={() => {
            dispatch(SiSlice.actions.setCustomModal({
              visible: false,
              content: '',
              showed: true
            }));
          }}
        >
          <IoCloseSharp />
        </button>
        <JsxParser 
          jsx={content}
          components={{ CustomModal }}
        />
      </div>
    </div>
  )
}

export default CustomModal;


