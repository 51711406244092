import React from "react";
import Vimeo from "@u-wave/react-vimeo";
import classnames from "classnames";

//redux
import { useSelector, useDispatch } from 'react-redux';
import AppSlice from "@store/app";

//components
import PersonalisedVideo from "@components/PersonalisedVideo/PersonalisedVideo";
import Image from "@components/Image";

//pictures and icons
import { ReactComponent as Close } from "@images/player-close.svg";

//styles
import "./style.css";

const Instructions = ({ children, rankingExample, persVideo }) => {

    const videoSource = useSelector(state => state.app.videoPlayer.source);
    const isPlaying = useSelector(state => state.app.videoPlayer.isPlaying);
    const dispatch = useDispatch();

    const stopVideo = () => {
        dispatch(AppSlice.actions.setVideoPlayer({
            source: '',
            isPlaying: false
        }))
    }

    const renderChildren = () => {
        const elements = React.Children.toArray(children);

        return (
            <div
                className={classnames("instructions__content", {
                    "instructions__content--image": rankingExample,
                })}
            >
                {elements}
                {rankingExample && (
                    <div className="instructions__content--image__wrapper">
                        <Image
                            src={require("@images/ranking-example.gif").default}
                            alt="Activity example"
                        />
                    </div>
                )}
                {persVideo && (
                    <div className="personalised-video-container">
                        <PersonalisedVideo 
                            src={persVideo.src}
                            bgClass={persVideo.bg}
                            name="PISL_general_video"
                            playedFrom="survey_component"
                        />   
                    </div>
                )}

            </div>
        );
    };

    return (
        <>
            <div className="instructions__content">
                <div className="instructions__content__background" />
                {renderChildren()}
            </div>

            {/* video player for PWC */}
            <div 
                className={`${isPlaying ? 'labels-video-playing' : 'labels-video-player'}`}
                onClick={ () => stopVideo() }
            >
                { videoSource   
                    ?<div className="video-modal">
                        <div className="video-modal__video-wrapper">
                        <canvas width="9" height="16" />
                        <div className="video-modal__video-wrapper--close">
                            <Close onClick={() => stopVideo() } />
                        </div>
                        <Vimeo
                            video={videoSource}
                            autoplay
                            muted={false}
                            width="100%"
                            height="100%"
                            className="video-modal__vimeo"
                        />
                        </div>
                    </div>
                    : null
                }
            </div>
        </>
    );
};

export default Instructions;
