import React from "react";
import "./style.css";
import * as Badges from "@images/badge";

const Badge = ({ className, level = 1, enabled = false }) => {
    const bKey = `Level${level}${enabled ? "Enabled" : "Disabled"}`;
    const TheBadge = Badges[bKey];
    if (!TheBadge) return null;
    return <TheBadge className={className} />;
};

export default Badge;
