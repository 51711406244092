import React from 'react'
import { ReactComponent as Background } from "@images/background.svg"
import { useSelector } from 'react-redux'
import cx from "classnames"
import './CStyleBackground.css'

const CStyleBackground = ({ className }) => {

  const myCStyle = useSelector(state => {
    const currentResults = state.firestore.data?.myCstyle?.results?.cStyle?.cStyleName;
    const previousResults = state.app.lastCompletedCStyle?.results?.cStyle?.cStyleName;
    
    if (currentResults) return currentResults;
    else if (previousResults) return previousResults;
    else return '';
  })
 
  return (
    <Background
      className={cx(
          "result_quadrant__background",
          `bg-${myCStyle?.toLowerCase()}`,
          `${className}`
      )}
    />
  )
}

export default CStyleBackground
