import useTakeCourse from '@hooks/useTakeCourse';
import React from 'react'
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import './ShowCompletedScience.css';

const ShowCompletedScience = ({ thankYouText }) => {
  const history = useHistory();
  const takeCourse = useTakeCourse();
  const nextCourse = useSelector(state => state.firestore?.data?.currentCourse?.nextCourse);

  return (
    <div className="survey-component__survey__thanks-page">
      <div className="survey-component__survey__thanks-page__background" />
      <h1>
        { thankYouText || "Thank you for completing the course!" }
      </h1>

      {nextCourse 
        ?<div className="completed-science-btns-container">
            <button
              className="button-secondary"
              onClick={() => history.push('/')}
            >
              Dashboard
            </button>

            <button
              className="button-primary"
              onClick={() => takeCourse(nextCourse.id, 'take_course', nextCourse.name)}
            >
              { nextCourse && nextCourse.name }
            </button>
         </div>
        :<div className="completed-science-btns-container">
          <button
              className="button-primary"
              onClick={() => history.push('/')}
            >
              Dashboard
            </button>
         </div>
      }


    </div>
  )
}

export default ShowCompletedScience;
