export default function getCourseStats(course) {
    // determine number of pages with questions
    const pages = (course?.json?.pages || []).length;
    const currentPage = course?.results?.currentPageNo + 1 || 0;
    const answered = Object.keys(course?.results?.data || {}).length;
    const percentage = ((currentPage / pages) * 100).toFixed(0);
    const isCompleted = !!course?.timeCompleted;
    const isStarted = !!course?.timeStarted;
    const isInProgress = isStarted && !isCompleted;
    const isFuture = !isStarted && !isCompleted;

    return {
        currentPage,
        pages,
        answered,
        percentage,
        isCompleted,
        isStarted,
        isInProgress,
        isFuture,
    };
}
