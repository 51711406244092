import React from "react";
import "./style.css";
import PageWrapper from "@components/PageWrapper";
import classnames from "classnames";

const Message = (props) => {
    const {
        title,
        text,
        cta,
        secondaryCta,
        ctaAction,
        secondaryCtaAction,
        icon,
        label,
        video,
    } = props;

    return (
        <div className={"message"}>
            <PageWrapper>
                <h2>{title}</h2>
                <p className="larger"> {text}</p>
                {icon && <div className="message__icon"></div>}
                {label && <p className="subtitle">{label}</p>}
                {video && <div className="message__video-wrapper">{video}</div>}

                <div
                    className={classnames("message__cta", {
                        "message__cta--multiple": secondaryCta,
                    })}
                >
                    {secondaryCta && (
                        <button
                            className="button-secondary"
                            onClick={secondaryCtaAction}
                        >
                            {secondaryCta}
                        </button>
                    )}
                    {cta && (
                        <button className="button-primary" onClick={ctaAction}>
                            {cta}
                        </button>
                    )}
                </div>
            </PageWrapper>
        </div>
    );
};

export default Message;
