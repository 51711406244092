import { combineReducers } from "redux";
import { firebaseReducer } from "react-redux-firebase";
import { firestoreReducer } from "redux-firestore"; // <- needed if using firestore
import { configureStore, getDefaultMiddleware } from "@reduxjs/toolkit";
import { getFirebase, actionTypes as rrfActionTypes } from "react-redux-firebase";
import { constants as rfConstants } from "redux-firestore";

import userCourses from "./userCourses.js";
import app from "./app.js";
import si from "./si.js";

const rootReducer = combineReducers({
    firebase: firebaseReducer,
    firestore: firestoreReducer, // <- needed if using firestore
    app: app.reducer,
    userCourses: userCourses.reducer,
    si: si.reducer
});

const middleware = [
    ...getDefaultMiddleware({
        serializableCheck: {
            ignoredActions: [
                ...Object.keys(rfConstants.actionTypes).map(
                    (type) => `${rfConstants.actionsPrefix}/${type}`
                ),
                ...Object.keys(rrfActionTypes).map(
                    (type) => `@@reactReduxFirebase/${type}`
                ),
            ],
            ignoredPaths: ["firebase", "firestore"],
        },
        thunk: {
            getFirebase,
        },
    })
];

const store = configureStore({
    reducer: rootReducer,
    middleware,
});

export default store;
