import React from "react";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

import { ScrollableSectionWithBadge } from "@components/SectionWithBadge";
import PodcastTile from "@components/PodcastTile";
import PageWrapper from "@components/PageWrapper";
import Collapsible from "@components/Collapsible";

import "./style.css";
import useGroupedPodcastsList from "src/hooks/useGroupedPodcastsList";

const PodcastsPage = () => {
    const userId = useSelector((state) => state.firebase.auth.uid);
    const { t } = useTranslation();

    const { inProgress, future, completed } = useGroupedPodcastsList(
        userId
    ) || { inProgress: [], future: [], completed: [] };

    return (
        <div className="podcasts">
            <PageWrapper>
                <h1>{t("podcasts.pageHeader")}</h1>
                <Collapsible collapseHeight={80}>
                    <p className="larger">
                        {t("podcasts.mainHeader")}
                    </p>
                </Collapsible>
                <hr />
                {inProgress?.length > 0 && (
                    <ScrollableSectionWithBadge
                        title={t("podcasts.onGoingPodcastsText")}
                        badge={inProgress?.length}
                    >
                        {inProgress.map((podcast) => (
                            <PodcastTile
                                key={podcast.id}
                                podcast={podcast}
                                podcastId={podcast.id}
                            />
                        ))}
                    </ScrollableSectionWithBadge>
                )}

                {future?.length > 0 && (
                    <ScrollableSectionWithBadge
                        title={t("podcasts.toStartPodcastsText")}
                        badge={future?.length}
                    >
                        {future.map((podcast) => (
                            <PodcastTile
                                key={podcast.id}
                                podcast={podcast}
                                podcastId={podcast.id}
                            />
                        ))}
                    </ScrollableSectionWithBadge>
                )}
                {completed?.length > 0 && (
                    <ScrollableSectionWithBadge
                        title={t("podcasts.completedPodcastsText")}
                        badge={completed?.length}
                        noHr
                    >
                        {completed.map((podcast) => (
                            <PodcastTile
                                key={podcast.id}
                                podcast={podcast}
                                podcastId={podcast.id}
                            />
                        ))}
                    </ScrollableSectionWithBadge>
                )}
            </PageWrapper>
        </div>
    );
};

export default PodcastsPage;
