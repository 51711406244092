import { createSlice } from "@reduxjs/toolkit";

export default createSlice({
    name: "si",
    initialState: {
      sections: {
        all: {
          questions: [],
          min: 0,
          max: 10
        },
        beginning: {
          questions: [],
          min: 1,
          max: 2
        },
        middle: {
          questions: [],
          min: 1,
          max: 8
        },
        end: {
          questions: [],
          min: 1,
          max: 2
        }
      },
      sectionStatus: {
        all: {
          canRemove: true
        },
        beginning: {
          canAdd: true,
          canRemove: false
        },
        middle: {
          canAdd: true,
          canRemove: false,
        },
        end: {
          canAdd: true,
          canRemove: false
        }
      },
      customModal: {
        visible: false,
        content: '',
        showed: false
      },
      scienceEvents: []
    },
    reducers: {
      initSections: (state, action) => {
        state.sections.all.questions = action.payload;
      },
      setSections: (state, action) => {
        state.sections = action.payload;
      },
      setSectionStatus: (state, action) => {
        state.sectionStatus = action.payload;
      },
      resetSections: (state, action) => {
        state.sections = {
          all: {
            questions: [],
            min: 0,
            max: 10
          },
          beginning: {
            questions: [],
            min: 1,
            max: 2
          },
          middle: {
            questions: [],
            min: 1,
            max: 8
          },
          end: {
            questions: [],
            min: 1,
            max: 2
          }
        };
        state.sectionStatus = {
          all: {
            canRemove: true
          },
          beginning: {
            canAdd: true,
            canRemove: false
          },
          middle: {
            canAdd: true,
            canRemove: false,
          },
          end: {
            canAdd: true,
            canRemove: false
          }
        }
      },
      setCustomModal: (state, action) => {
        state.customModal = action.payload;
      },
      setScienceEvents: (state, action) => {
        state.scienceEvents = action.payload;
      }
    },  
});
