import React, { useCallback, useRef, useState, useEffect } from "react";
import Vimeo from "@u-wave/react-vimeo";

import { useFirestore } from "react-redux-firebase";
import { useSelector, useDispatch } from "react-redux";
import cx from "classnames";

import AppSlice from "@store/app";
import ActionPanel from "@components/ActionPanel";
import PlayPauseButton from "@components/PlayPauseButton";
import throttle from "lodash.throttle";
import { ReactComponent as Close } from "@images/player-close.svg";
import { ReactComponent as Arrow } from "@images/down-arrow.svg";
import { ReactComponent as Expand } from "@images/expand.svg";

import "./style.css";

const PodcastPlayer = ({ className, onClick }) => {

    const firestore = useFirestore();
    const podcastId = useSelector((state) => state.app.player.id);
    const player = useSelector((state) => state.app.player);
    const userId = useSelector((state) => state.firebase.auth.uid);
    const dispatch = useDispatch();

    const playerRef = useRef();

    const podcast = useSelector(
        (state) => state.firestore.data?.myPodcasts?.[podcastId]
    );

    const vimeoId = useSelector(
        (state) => state.firestore.data?.podcasts?.[podcastId]?.vimeoId
    );

    const [minified, setMinified] = useState(true);

    useEffect(() => {
        if (!player.isPlaying && !player.isVisible) {
            setMinified(true);
        }
    }, [player.isPlaying, player.isVisible]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    const onProgress = useCallback(
        throttle(({ seconds, duration }) => {
            const hasEnded = seconds >= duration;

            if (hasEnded) {
                dispatch(
                    AppSlice.actions.setPlayer({
                        id: null,
                        isPlaying: false,
                    })
                );
                setMinified(true);
            }

            firestore.update(
                {
                    collection: `users/${userId}/userPodcasts`,
                    doc: `${podcastId}`,
                },
                {
                    time: hasEnded ? 0 : seconds,
                    duration: duration,
                    timeCompleted: hasEnded
                        ? new Date().getTime()
                        : podcast.timeCompleted || null,
                    timeStarted: !podcast.timeStarted
                        ? new Date().getTime()
                        : podcast.timeStarted || null,
                }
            );
        }, 1000),
        [userId, podcastId]
    );

    const defaultOnClick = useCallback(
        (e) => {
            dispatch(
                AppSlice.actions.setPlayer({
                    id: podcastId,
                })
            );
        },
        [dispatch, podcastId]
    );

    const onPlayClick = useCallback(
        (e) => {
            e.stopPropagation();
            onClick ? onClick() : defaultOnClick();
        },
        [defaultOnClick, onClick]
    );

    const onReady = useCallback((ref) => {
        playerRef.current = ref;
    }, []);

    const onPause = useCallback(
        (e) => {
            dispatch(
                AppSlice.actions.setPlayer({
                    id: podcastId,
                    isPlaying: false,
                    // isVisible: true,
                })
            );
        },
        [dispatch, podcastId]
    );

    const onPlay = useCallback(
        (e) => {
            dispatch(
                AppSlice.actions.setPlayer({
                    id: podcastId,
                    isPlaying: true,
                    // isVisible: true,
                })
            );
        },
        [dispatch, podcastId]
    );

    const dismissPlayer = useCallback(
        (e) => {
            e.stopPropagation();
            dispatch(
                AppSlice.actions.setPlayer({
                    id: podcastId,
                    isPlaying: false,
                    isVisible: false,
                })
            );
        },
        [dispatch, podcastId]
    );

    return (
        <ActionPanel
            forPlayer
            visible={player.id && player.isVisible}
            minified={minified}
        >
            {!minified && (
                <>
                    <div
                        className={cx("podcast-player__close-button", {
                            "podcast-player__close-button--minified": minified,
                        })}
                        onClick={dismissPlayer}
                    >
                        <Close />
                    </div>

                    <div
                        className="podcast-player__minify-button"
                        onClick={() => setMinified(!minified)}
                    >
                        <Arrow />
                    </div>
                </>
            )}

            {minified && (
                <>
                    <div className={cx("podcast-player", className)}>
                        <div className={cx("podcast-player__button-wrapper")}>
                            <PlayPauseButton
                                isPlaying={player.isPlaying}
                                onClick={onPlayClick}
                                className="podcast-player__expand-button"
                            />
                        </div>
                        <div className={cx("podcast-player__title-wrapper")}>
                            <span
                                className={cx("podcast-player__playback-type")}
                            >
                                podcast
                            </span>
                            <div className="podcast-player__marquee">
                                <div>
                                    <span>{podcast?.title} </span>
                                    <span>{podcast?.title}</span>
                                </div>
                            </div>
                        </div>
                        <div className={cx("podcast-player__button-wrapper")}>
                            <Expand
                                className="podcast-player__mini-play-button"
                                onClick={() => setMinified(!minified)}
                            />
                            <div
                                className="podcast-player__mini-close-button"
                                onClick={dismissPlayer}
                            >
                                <Close />
                            </div>
                        </div>
                    </div>
                </>
            )}

            {podcast && vimeoId && (
                <div
                    style={{
                        width: minified ? 0 : "100%",
                        height: minified ? 0 : "100%",
                        overflow: "hidden",
                    }}
                >
                    <Vimeo
                        key={player.id}
                        video={vimeoId}
                        autoplay
                        muted={false}
                        start={podcast?.time || 0}
                        paused={!player.isPlaying}
                        onTimeUpdate={onProgress}
                        onPause={onPause}
                        onPlay={onPlay}
                        onReady={onReady}
                        width={minified ? null : "100%"}
                        height={minified ? null : "100%"}
                        className="action-panel--for-player--wrapper"
                    />
                </div>
            )}
        </ActionPanel>
    );
};

export default React.memo(PodcastPlayer);
