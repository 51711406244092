import React from "react";
import "./style.css";
import { ReactComponent as Background } from "@images/background.svg";
import classnames from "classnames";
import { useTranslation } from "react-i18next";

const Tip = ({ cstyle, children }) => {
    const { t } = useTranslation();
    
    return (
        <div className="tip">
            {cstyle ? (
                <div className="tip tip--cstyle">
                    <p className="tip--label subtitle">{t('tip.title')}</p>
                    <h3>{t(`tip.${cstyle}`)} {t('tip.cStyle')}</h3>
                    <div className="tip--cstyle__banner-wrapper">
                        <div
                            className={classnames(
                                "tip--cstyle__banner",
                                cstyle && `bg-${cstyle}`
                            )}
                        >
                            <Background />
                        </div>
                    </div>
                    {children}
                </div>
            ) : (
                <h3>
                    <p className="tip--label subtitle">TIP</p>
                    <div className="tip--content">{children}</div>
                </h3>
            )}
        </div>
    );
};

export default Tip;
