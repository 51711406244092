import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from "react-i18next";
import { analyticsLogEvent } from '@components/Analytics';
import EN from "@assets/flags/EN.svg";
import ES from "@assets/flags/ES.svg";
import PT from "@assets/flags/PT.svg";

import "./Flags.css";

const Flags = () => {
  const { i18n } = useTranslation();
  const allowMultiLingual = useSelector(state => state?.app?.allowMultiLingual);

  const handleLanguageChange = (language) => {
    document.querySelectorAll('.selected')?.forEach(elem => elem.classList.remove('selected'));
    document.querySelectorAll(`.${language}`)?.forEach(elem => elem.classList.add('selected'));
    i18n.changeLanguage(language);
    window.localStorage.setItem("rippl-pref-language", language);
  }

  useEffect(() => {
    handleLanguageChange(i18n.language);
  }, [i18n.language]);

  if (!allowMultiLingual) return null;
  return (
    <div className="flags-container">
      <div id="en" className="flag en" onClick={() => {analyticsLogEvent('change_language', { name: 'en' }); handleLanguageChange("en")}}>
        <img src={EN} alt="english flag" />
      </div>
      <div id="es" className="flag es" onClick={() => {analyticsLogEvent('change_language', { name: 'es' }); handleLanguageChange("es")}}>
        <img src={ES} alt="spanish flag" />
      </div>
      <div id="pt" className="flag pt" onClick={() => {analyticsLogEvent('change_language', { name: 'pt' }); handleLanguageChange("pt")}}>
        <img src={PT} alt="portuguese flag" />
      </div>
    </div>
  )
}

export default Flags
