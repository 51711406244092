import React, { useCallback } from "react";
import "./style.css";
import AppSlice from "@store/app";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import UpdateProfile from "@components/UpdateProfile";
import LevelInfoModal from "@components/LevelInfoModal";
import EmailModal from "@components/EmailModal/EmailModal";
import LevelUpModal from "@components/LevelUpModal";
import Message from "@components/Message";
import { ReactComponent as CloseButton } from "@images/close.svg";
import CertificateView from "@components/CertificateView";
import ResultQuadrant from "@components/ResultQuadrant";
import JsxParser from "react-jsx-parser";
import PageWrapper from "@components/PageWrapper";
import CoursesProgressChart from "@components/CoursesProgressChart";
import classnames from "classnames";
import { v4 as uuid } from 'uuid';
import { useTranslation } from "react-i18next";

const Modal = (props) => {
    const { content } = props;

    const dispatch = useDispatch();
    const history = useHistory();
    const { t } = useTranslation();

    const close = useCallback(() => {
        if (content.type === "welcome") {
            dispatch(AppSlice.actions.setWelcome(false));
        }
        if (content.type === "certificate" && content.isFirstTimeShown) {
            dispatch(AppSlice.actions.setCStyleCertificateViewed(true));
        }
        dispatch(AppSlice.actions.setModal({ visible: false, content: null }));
    }, [dispatch, content.type, content.isFirstTimeShown]);

    const closeFirstPhaseModal = useCallback(() => {
        dispatch(AppSlice.actions.setModal({ visible: false, content: null, showed: true }));
    }, [dispatch]);

    const userId = useSelector((state) => state.firebase.auth.uid);
    const mainCourseId = useSelector(
        (state) => state.firestore.data.users?.[`${userId}`]?.mainCourse
    );
    const cStyleDefinition = useSelector(
        (state) =>
            state.app.mainCourse &&
            state.app.mainCourse.json &&
            JSON.parse(state.app.mainCourse.json)
    );

    const goToMainCourse = useCallback(
        (e) => {
            // close modal and close menu
            close();
            dispatch(AppSlice.actions.setMenu(false));
            history.push(`/course/${mainCourseId}`);
        },
        [history, dispatch, mainCourseId, close]
    );

    const fbRemoteConfig = useSelector((state) => state.app.fbRemoteConfig);

    const getResultQuadrant = useCallback(
        (id) => {
            if (cStyleDefinition && content.type === "resultQuadrant") {
                const resPage =
                    cStyleDefinition.pages[cStyleDefinition.pages.length - 1];

                let resMarkUp =
                    resPage.elements &&
                    resPage.elements[0] &&
                    resPage.elements[0].html;

                if (resMarkUp) {
                    const regexp = new RegExp(
                        `<ResultQuadrant[^>]*id=\\"${id}\\"[^>]*>.*?</ResultQuadrant>`,
                        "s"
                    );

                    resMarkUp = resMarkUp.match(regexp);

                    if (resMarkUp && resMarkUp[0]) {
                        resMarkUp = resMarkUp[0];
                    } else {
                        return false;
                    }

                    resMarkUp = resMarkUp.replace(
                        /<ResultQuadrant/,
                        "<ResultQuadrant noRange "
                    );
                    resMarkUp = resMarkUp.replace(
                        /<ResultZone[^>]*>.*?<\/ResultZone>/gs,
                        ""
                    );
                    return (
                        <PageWrapper>
                            <JsxParser
                                autoCloseVoidElements
                                components={{ ResultQuadrant }}
                                jsx={resMarkUp}
                            />
                        </PageWrapper>
                    );
                } else {
                    return false;
                }
            }
        },
        [cStyleDefinition, content.type]
    );

    const MODAL_VARIANTS = {
        welcome: {
            component: (
                <Message
                    title={t('modals.welcome.title')}
                    text={t('modals.welcome.text')}
                    cta={t('modals.welcome.cta')}
                    ctaAction={goToMainCourse}
                />
            ),
        },
        styleRequired: {
            component: (
                <Message
                title={t('modals.beforeStart.title')}
                text={t('modals.beforeStart.text')}
                cta={t('modals.beforeStart.cta')}
                    ctaAction={goToMainCourse}
                    secondaryCta={t('modals.beforeStart.secondaryCta')}
                    secondaryCtaAction={close}
                />
            ),
        },
        certificate: {
            title: "Certificate",
            component: (
                <CertificateView
                    courseName={content?.data?.courseName}
                    timeValid={content?.data?.timeValid}
                />
            ),
        },
        unlockBadge: {
            component: <LevelUpModal onClose={close} />,
        },
        badgeInfo: {
            component: <LevelInfoModal onClose={close} />,
        },

        editProfile: {
            title: t('profile.title'),
            component: <UpdateProfile modal />,
        },
        privacyPolicy: {
            title: "Privacy & Cookies",
            component: (
                <iframe
                    title={uuid()}
                    src={`${fbRemoteConfig.privacyPolicy}`}
                    frameBorder="0"
                ></iframe>
            ),
            showGradient: true,
        },
        cookiePolicy: {
            title: "Privacy & Cookies",
            component: (
                <iframe
                    title={uuid()}
                    src={`${fbRemoteConfig.privacyPolicy}`}
                    frameBorder="0"
                ></iframe>
            ),
            showGradient: true,
        },
        termsAndConditions: {
            title: "Terms & Conditions",
            component: (
                <iframe
                    title={uuid()}
                    src={`${fbRemoteConfig.termsAndConditions}`}
                    frameBorder="0"
                ></iframe>
            ),
            showGradient: true,
        },
        resultQuadrant: {
            title: `${content.id} C-Style`,
            component: getResultQuadrant(content.id),
        },
        coursesProgress: {
            title: "Courses Progress",
            component: (
                <PageWrapper>
                    <CoursesProgressChart />
                </PageWrapper>
            ),
        },
        SI_FirstPhase_Done: {
            component: (
                <Message
                    title="Well done"
                    text="With the 10 messages sorted, you can read speaker notes by selecting ‘show note’. Use the notes to help you refine the order of each section by using the up and down arrows."
                    cta="Got it"
                    ctaAction={closeFirstPhaseModal}
                />
            ),
        },
        inviteFriend: {
            component: <EmailModal inviteFriend={true} onClose={close} />,
        },
        shareResults: {
            component: <EmailModal shareResults={true} onClose={close} />,
        },
    };

    const variant = MODAL_VARIANTS[content.type]?.component;
    const title = MODAL_VARIANTS[content.type]?.title;
    const showGradient = MODAL_VARIANTS[content.type]?.showGradient;

    return (
        <div
            className={classnames("modal", {
                "modal--cstyle": content.type === "resultQuadrant",
                "modal--iframe":
                    content.type === "privacyPolicy" ||
                    content.type === "cookiePolicy" ||
                    content.type === "termsAndConditions",
            })}
        >
            <div className="modal__panel">
                {/* only show title & close button if modal variant has a title */}
                {title && (
                    <>
                        <div className="modal__panel__heading">
                            <p className="subtitle">{title}</p>
                            <CloseButton
                                className="modal__panel__heading__close"
                                onClick={close}
                            />
                        </div>
                    </>
                )}
                {variant}
                {showGradient && <div className="modal__panel__gradient" />}
            </div>
        </div>
    );
};

export default Modal;
