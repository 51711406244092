import React, { useState } from "react";
import "./style.css";
import Vimeo from "@u-wave/react-vimeo";
import { IoCloseCircleOutline } from "react-icons/io5";
import { analyticsLogEvent } from "@components/Analytics";
import { v4 as uuid } from 'uuid';

const Video = ({ children, className }) => {
    const [videoModal, setVideoModal] = useState();
    const {
        src,
        frameborder,
        allowfullscreen,
        allow,
        width,
        height,
        frameBorder,
        allowFullScreen,
        title
    } = children?.props;

    return (
        <>
            <div
                className="video-wrapper"
                onClick={() => {
                    analyticsLogEvent('play_video', {name: title, playedFrom:  "survey_component"})
                    setVideoModal(!videoModal)
                }}
            >
                <div className="video-wrapper__overlay" />
                <iframe
                    title={uuid()}
                    src={src}
                    frameBorder={frameborder || frameBorder}
                    allowFullScreen={allowfullscreen || allowFullScreen}
                    allow={allow}
                    width={width}
                    height={height}
                ></iframe>
            </div>
            {videoModal && src && (
                <div 
                    className="video-modal"
                    onClick={() => {
                        analyticsLogEvent('stop_video', {name: title})
                        setVideoModal(!videoModal)
                    }}
                >
                    <div className="video-modal__video-wrapper">
                        <canvas width="9" height="16" />
                        <div className="video-modal__video-wrapper--close">
                            <IoCloseCircleOutline 
                                onClick={() => {
                                    analyticsLogEvent('stop_video', {name: title})
                                    setVideoModal(!videoModal)
                                }}
                            />
                        </div>
                        <Vimeo
                            video={src}
                            autoplay
                            muted={false}
                            width="100%"
                            height="100%"
                            className="video-modal__vimeo"
                        />
                    </div>
                </div>
            )}
        </>
    );
};

export default Video;
