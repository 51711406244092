import React, { useContext, useEffect, useCallback } from "react";
import "./style.css";
import Video from "@components/Video";
import usePagination from "@hooks/usePagination";
import PaginationControls from "@components/PaginationControls";
import classnames from "classnames";
import { QuestionContext } from "@components/SurveyComponent/customWidgets";
import { useTranslation } from "react-i18next";
const Pagination = ({ children, sortChildren, answers, canSkip }) => {
    return (
        <>
            {sortChildren ? (
                <TipsPagination
                    children={children}
                    answers={answers}
                    canSkip={canSkip}
                />
            ) : (
                <VideoPagination children={children} canSkip={canSkip} />
            )}
        </>
    );
};

export default Pagination;

const VideoPagination = ({ children, canSkip }) => {
    const question = useContext(QuestionContext);

    const { next, prev, currentPage, maxPages } = usePagination(
        children,
        1,
        question.currentPage
    );

    useEffect(() => {
        question.isRequired = true;
        question.currentPage = currentPage;
        if (currentPage === maxPages - 1) {
            question.isAnswered = true;
        } else {
            question.isAnswered = false;
        }
        //trigger value changed
        question.data.onValueChanged.callbacks[0](question.data);
    }, [question, currentPage, maxPages]);

    const renderChildren = () => {
        const elements = React.Children.toArray(
            children[currentPage].props.children
        );

        const iframeIndex = elements.findIndex((e) => e.type === "iframe");

        return (
            <>
                {iframeIndex && (
                    <div className="pagination--video__video-container">
                        <Video>{elements[iframeIndex]}</Video>
                        <PaginationControls
                            className="mobile-controls"
                            currentPage={currentPage}
                            maxPages={maxPages}
                            next={next}
                            prev={prev}
                        />
                    </div>
                )}
                <div className="pagination--video__content">
                    <div className="pagination--video__content__copy">
                        {elements.map((el, i) => {
                            return i !== iframeIndex && el;
                        })}
                    </div>
                    <PaginationControls
                        className="video"
                        currentPage={currentPage}
                        maxPages={maxPages}
                        next={next}
                        prev={prev}
                    />
                </div>
            </>
        );
    };

    return <div className="pagination--video">{renderChildren()}</div>;
};

const TipsPagination = ({ children, answers, canSkip }) => {
    const { name: pageName, questions } = answers || {};
    const language = useTranslation().i18n.language;
    const filterChildren = useCallback(() => {
        let relatedQuestions;

        if (pageName === "tips-wrong") {
            // questions given a wrong answer
            relatedQuestions = questions.filter(
                (q) =>
                    q.commentText.match(/tip\d/) && q.value !== q.correctAnswer
            );
        } else {
            // questions given a right answer
            relatedQuestions = questions.filter(
                (q) =>
                    q.commentText.match(/tip\d/) && q.value === q.correctAnswer
            );
        }

        relatedQuestions = Array.from(
            new Set(relatedQuestions.map((a) => a.commentText))
        )
            .map((id) => {
                return relatedQuestions.find((a) => a.commentText === id);
            })
            .sort((a, b) => {
                return (
                    Number(a.commentText.match(/(\d+)/g)[0]) -
                    Number(b.commentText.match(/(\d+)/g)[0])
                );
            });

        const tipsToDisplay =
            (relatedQuestions &&
                React.Children.toArray(children).filter((el) => {
                    return relatedQuestions.some((f) => {
                        return f.commentText === el.props.name;
                    });
                })) ||
            [];

        return relatedQuestions.length === 0
            ? []
            : [relatedQuestions, tipsToDisplay];
    }, [questions, children, pageName]);

    const question = useContext(QuestionContext);

    const { next, prev, currentPage, maxPages } = usePagination(
        filterChildren()[1],
        1,
        question.currentPage
    );

    const skipToNextPage = () => {
        question.data.nextPage();
    };

    useEffect(() => {
        const filteredChildren = filterChildren();

        question.isRequired = true;
        question.currentPage = currentPage;
        if (currentPage === maxPages - 1 || filteredChildren.length === 0) {
            question.isAnswered = true;
        } else {
            question.isAnswered = false;
        }
        //trigger value changed
        question.data.onValueChanged.callbacks[0](question.data);
    }, [question, currentPage, maxPages, filterChildren]);

    const renderChildren = () => {
        const filteredChildren = filterChildren();

        if (filteredChildren && filteredChildren.length > 0) {
            return (
                <>
                    <div
                        className={classnames("pagination--tips__heading", {
                            "pagination--tips__heading--wrong":
                                pageName === "tips-wrong",
                        })}
                    >
                        <p className="subtitle">
                            {obj[filteredChildren[0][currentPage].value][language]}
                            
                        </p>
                        <h4>{filteredChildren[0][currentPage].title}</h4>
                    </div>
                    <PaginationControls
                        className="tips"
                        currentPage={currentPage}
                        maxPages={maxPages}
                        next={next}
                        prev={prev}
                        skip={canSkip && skipToNextPage}
                    />
                    {filteredChildren[1][currentPage]}
                </>
            );
        } else {
            return (
                <div className="pagination--tips__no-tips">
                    <div className="pagination--tips__no-tips__background" />
                    {pageName === "tips-wrong" ? (
                        <>
                            <h3>You mastered the topic!</h3>
                            <p>
                                Go to the next screen if you want to review our
                                tips, just in case!
                            </p>
                        </>
                    ) : (
                        <>
                            <h3>Nothing to add!</h3>{" "}
                            <p>
                                You've alrready reviewed all our tips and now
                                you're ready for the next step!
                            </p>
                        </>
                    )}
                </div>
            );
        }
    };

    return <div className="pagination--tips">{renderChildren()}</div>;
};

const obj = {
    agree: {
        en: 'YOU AGREED WITH:',
        es: 'ESTAS DE ACUERDO CON:',
        pt: 'VOCÊ ACEITOU:'
    },
    disagree: {
        en: 'YOU DISAGREED WITH:',
        es: 'USTED NO ESTÁ DE ACUERDO CON',
        pt: 'VOCÊ DESCORREU COM'
    }
}