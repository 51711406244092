import React from "react";
import cx from "classnames";

import "./style.css";

const HorizontalScroll = React.forwardRef(
    ({ children, className, wrapperClassName, vertical, ...props }, ref) => {
        return (
            <div
                className={cx(
                    "horizontal-scroll__wrapper horizontal-scroll--full-width",
                    wrapperClassName,
                    { "horizontal-scroll__wrapper--vertical": vertical }
                )}
            >
                <div
                    className={cx(
                        `horizontal-scroll horizontal-scroll--no-scrollbar `,
                        className,
                        { "horizontal-scroll--vertical": vertical }
                    )}
                    ref={ref}
                    {...props}
                >
                    {children}
                </div>
            </div>
        );
    }
);

export default HorizontalScroll;
