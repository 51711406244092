import React, { useRef, useState } from "react";
import HorizontalScroll from "@components/HorizontalScroll";
import cx from "classnames";

import "./style.css";
import useInterval from "src/hooks/useInterval";
import arrowButton from "@images/arrow-button.svg";

const SectionWithBadge = ({
    badge,
    title,
    children,
    noHr,
    scrollerRef,
    ...rest
}) => {
    const [buttons, setButtons] = useState(false);
    const [leftEnabled, setLeftEnabled] = useState(false);
    const [rightEnabled, setRightEnabled] = useState(false);
    useInterval(() => {
        if (!scrollerRef) return;

        const enabled = scrollerRef.scrollWidth - scrollerRef.clientWidth > 5;
        setButtons(enabled);
        if (enabled) {
            setLeftEnabled(scrollerRef.scrollLeft > 10);
            setRightEnabled(
                scrollerRef.scrollWidth -
                    scrollerRef.clientWidth -
                    scrollerRef.scrollLeft >
                    10
            );
        }
    }, 300);

    return (
        <section
            className={cx("section-with-badge", {
                "at-end": !rightEnabled,
                "at-start": !leftEnabled,
            })}
        >
            <h2 className="section-with-badge__title">
                {title}
                {badge ? (
                    <div className="section-with-badge__badge">{badge}</div>
                ) : null}
                {buttons && scrollerRef && (
                    <div className={"section-with-badge__buttons"}>
                        <button
                            disabled={!leftEnabled}
                            className="section-with-badge__button section-with-badge__button--left"
                            onClick={() => {
                                scrollerRef.scrollTo({
                                    left: scrollerRef.scrollLeft - 300,
                                    behavior: "smooth",
                                });
                            }}
                        >
                            <img src={arrowButton} title="left" alt="" />
                        </button>
                        <button
                            disabled={!rightEnabled}
                            className="section-with-badge__button section-with-badge__button--right"
                            onClick={() => {
                                scrollerRef.scrollTo({
                                    left: scrollerRef.scrollLeft + 300,
                                    behavior: "smooth",
                                });
                            }}
                        >
                            <img src={arrowButton} title="left" alt="" />
                        </button>
                    </div>
                )}
            </h2>

            {children}
            {!noHr && <hr className="section-with-badge__divider" />}
        </section>
    );
};

export const ScrollableSectionWithBadge = ({ children, vertical, ...rest }) => {
    const scrollRef = useRef(null);

    return (
        <SectionWithBadge {...rest} scrollerRef={scrollRef.current}>
            <HorizontalScroll ref={scrollRef} vertical={vertical}>
                {children}
            </HorizontalScroll>
        </SectionWithBadge>
    );
};

export default SectionWithBadge;
