import React, { useState, useEffect } from 'react'
import { useHistory, Link } from "react-router-dom";
import JsxParser from 'react-jsx-parser';
import firebase from 'firebase';
import useTakeCourse from '@hooks/useTakeCourse';
import { useTranslation } from 'react-i18next'

//redux
import { useSelector } from 'react-redux';
import { useFirestoreConnect } from 'react-redux-firebase';

//components
import CustomProgressBar from '@components/CustomProgressBar/CustomProgressBar';
import PersonalisedVideo from '@components/PersonalisedVideo/PersonalisedVideo';
import RecommendedVideo from '@components/RecommendedVideo/RecommendedVideo';
import CustomVideoPlayer from '@components/Video/CustomVideoPlayer';
import PageSpinner from "@components/PageSpinner/PageSpinner";
import PageWrapper from '@components/PageWrapper';

//style
import './AssertivenessResults.css';

const AssertivenessResults = () => {
  const takeCourse = useTakeCourse();
  const history = useHistory();
  const userId = useSelector(state => state.firebase.auth?.uid);
  const lastCompletedAssertiveness = useSelector(state => state.app.lastCompletedAssertiveness);
  const { t, i18n } = useTranslation();
  const language = i18n.language;
  
  const cStyleName = useSelector(state => {
    const currentResults = state.firestore.data?.myCstyle?.results?.cStyle?.cStyleName;
    const previousResults = state.app.lastCompletedCStyle?.results?.cStyle?.cStyleName;
    
    if (currentResults) return currentResults;
    else if (previousResults) return previousResults;
    else return '';
  })
  console.log(cStyleName);
  
  const [showSpinner, setShowSpinner] = useState(true);

  const [rerender, setRerender] = useState(false);
  const [topResult, setTopResult] = useState('Loading...');
  const [bottomResult, setBottomResult] = useState('Loading...');

  const [header, setHeader] = useState('');
  const [firstParagraph, setFirstParagraph] = useState('');
  const [secondParagraph, setSecondParagraph] = useState('');
  const [bottomText, setButtomText] = useState('');

  const [percents, setPercents] = useState({});
 
  useFirestoreConnect([
    {
      collection: "courses",
      where: [["name.en", "==", `Building assertiveness`]],
      storeAs: "AssertivenessCourse",
    }
  ]);

  const assertivenessCourseId = Object.keys(useSelector(state => state.firestore?.data?.AssertivenessCourse) || [])[0];

  useEffect(() => {
    let isMounted = true;
    if (isMounted) {
      const getText = () => {

        //get header
        const regexp = new RegExp(`<h3>.*?</h3>`,'gs');
        const result = topResult.match(regexp) || [''];
        setHeader(result[0])
  
        //get top main paragraphs
        const pRegExp = new RegExp(`<p.*?/p>`,'gs');
        const pResults = topResult.match(pRegExp) || [''];
        setFirstParagraph(pResults[0]);
        setSecondParagraph(pResults[1]);
  
        //get bottom paragraphs
        const bpRegExp = new RegExp(`<h3>.*?</p>`, 's');
        const bpResults = bottomResult.match(bpRegExp) || [''];
        setButtomText(bpResults[0]); 
      }
      getText();
    }

    return () => { isMounted = false; }
  }, [topResult, bottomResult])

  useEffect(() => {
    let isMounted = true;
    if (isMounted) {
      const fetchAssertivenessResults = async () => {
        if(assertivenessCourseId) {
          const data = await firebase.firestore()
            .collection('users')
            .doc(userId)
            .collection('userCourses')
            .doc(assertivenessCourseId)
            .get();
    
          const firstPageResult = data.data()?.json?.pages[10]?.elements[0]?.html[language] || {};
          setTopResult(firstPageResult) 
    
          const secondPageResult = data.data()?.json?.pages[11]?.elements[0]?.html[language] || {};
          setBottomResult(secondPageResult);
    
          const per = data.data()?.results?.percents || lastCompletedAssertiveness;
          per ? setPercents(per) : setRerender(state => !state);
        }
      }
  
      fetchAssertivenessResults();
    }

    return () => { isMounted = false; }
  
  }, [assertivenessCourseId, userId, rerender, lastCompletedAssertiveness])
  
  // Set show spinner to false when the component is fully loaded 
  useEffect(() => {
    if (header && firstParagraph && secondParagraph && bottomText) {
      setShowSpinner(false);
    }
  }, [header, firstParagraph, secondParagraph, bottomText])

  if (showSpinner) {
    return <PageSpinner />
  }
  return (
    <div className="assertiveness-results">
      <PageWrapper>
        <div className="location-header">
          <div>
            <Link to="/">
                {t('resultsPages.bottomButtons.dash')}
            </Link>
            <span className="profile-span">{t('resultsPages.assertiveness')}</span>
          </div>
          
          <div className="retake-btn">
            <button
                className="button-primary button-primary--desktop  button-primary--remake"
                onClick={() => takeCourse(assertivenessCourseId, 'take_course', 'Building assertiveness') }
            >
                {t('resultsPages.buttons')}
            </button>
          </div>
        </div>   

        <div className="text-section">
          {<JsxParser jsx={header}/>}
    
          {<JsxParser jsx={firstParagraph}/>}
        
          {<JsxParser jsx={secondParagraph}/>}
        </div>

        <div className="chart-section">
          <h1>{t('resultsPages.testResults')}</h1>
          {percents && Object.keys(percents).map(type => {
            return <CustomProgressBar 
              key={type}
              percent={percents[type]} 
              title={chartText[type].title[language]} 
              text={chartText[type].text[language]} 
            />
          })}
        </div>

        <div className="bottom-text-section">
          {<JsxParser jsx={bottomText}/>}
        </div>

        <div className="personalised-video-container">
          {
              assertivenessVideos.map((video, index) => {
                if (video.cStyleName === cStyleName)
                  return(
                    <PersonalisedVideo 
                        key={index}
                        src={video.link}
                        bgClass={`Assertiveness-${video.cStyleName}`}
                        name={`Assertiveness-${video.cStyleName}`}
                        playedFrom="assertiveness_personalised_video"
                    />
                  )
                return null;
              })
          }
        </div>

        <div className="recommended-videos-container">
          <h1>{t('resultsPages.recommendedVideo')}</h1>
          <div className="videos-container">
            
            {
              assertivenessVideos.map((video, index) => {
                if(video.cStyleName !== cStyleName) {
                  return <RecommendedVideo 
                          key={index}
                          circle={video.cStyleCircle} 
                          insidePicture={video.assertivenessPicture} 
                          videoLink={video.link}
                          h1Text={video.h1Text[language]}
                          pText={video.pText[language]}
                          name={`Assertiveness-${video.cStyleName}`}
                          playedFrom="assertiveness_recommended_video"
                        />
                }
                return null;
              })
            }
          </div>
        </div>

        <div className="bottom-btns-container">
          <button 
            className="button-primary"
            onClick={() => { history.push('/') }}
          >
            {t('resultsPages.bottomButtons.dash')}
          </button>
          <button 
            className="button-secondary"
            onClick={() => { history.push('/courses') }}
          >
            {t('resultsPages.bottomButtons.exploreCourses')}
          </button>
        </div>

        {/* video player for assertiveness */}
        <CustomVideoPlayer />

      </PageWrapper>
    </div>
  )
}

export default AssertivenessResults;

const chartText = {
  'Passive': {
    title: {
      en: "Passive",
      es: "Passivo",
      pt: "Passivo"
    },
    text: {
      en: 'Compliant, submissive, quiet, vague, non-committal, self-deprecating, praising of others.',
      es: 'Obediente, sumisa, callada, vaga, no comprometida, autocrítica, alabando a los demás.',
      pt: 'Compatível, submisso, quieto, vago, sem compromisso, autodepreciativo, elogiador dos outros.'
    }
  },
  'Passive/Aggressive': {
    title: {
      en: "Passive/Aggressive",
      es: "Pasivo/Agresivo",
      pt: "Passivo/Agressivo"
    },
    text: {
      en: 'Quiet, verbally compliant but undermining, thoughts, feelings behaviour do not line up; expresses negative feelings indirectly or unclearly.',
      es: 'Tranquilo, dócil verbalmente, pero debilita los pensamientos, sentimientos y &amp; el comportamiento no se alinea; expresa sentimientos negativos de forma indirecta o poco clara..',
      pt: 'Silencioso, verbalmente compatível, mas subversivo em pensamentos, e sentimentos; comportamento desalinhado; expressa sentimentos negativos indiretamente ou sem clareza.',
    }
  },
  'Aggressive': {
    title: {
      en: "Aggressive",
      es: "Agresivo",
      pt: "Agressivo"
    },
    text: {
      en: 'Sarcastic, harsh, always right, know-it-all, interrupts, ignores, critical, insulting, patronising, dismissive or disrespectful.',
      es: 'Sarcástico, severo, siempre tiene la razón, sabelotodo, interrumpe, ignora, critica, insulta, es condescendiente, despectivo o irrespetuoso.',
      pt: 'Sarcástico, áspero, sempre certo, sabe-tudo, interrompe, ignora, crítico, insultuoso, paternalista, desdenhoso ou desrespeitoso.'
    }
  },
  'Assertive': {
    title: {
      en: "Assertive",
      es: "Asertivo",
      pt: "Assertivo"
    },
    text: {
      en: 'Feelings, thoughts behaviour are aligned, firm but considerate, clear, respectful of self and others.',
      es: 'Sentimientos, pensamientos y el comportamiento es alineado, firme pero considerado, claro, respetuoso de sí mismo y de los demás.',
      pt: 'Sentimentos, pensamentos &amp; e comportamento alinhados, firme, mas atencioso, claro e respeitoso consigo mesmo e com os outros.'
    }
  }
}

const assertivenessVideos = [
  {
      cStyleName: 'connecting',
      cStyleCircle: 'ConnectingCircle',
      assertivenessPicture: 'AssertivenessConnecting',
      link: 'https://player.vimeo.com/video/466232041',
      pText: {
        en: 'Assertive with a',
        es: 'Asertivo con un',
        pt: 'Assertivo com um'
      },
      h1Text: {
        en: 'CONNECTING STYLE',
        es: 'ESTILO DE CONNECTING',
        pt: 'ESTILO DE CONNECTING'
      }
  },
  {
      cStyleName: 'influencing',
      cStyleCircle: 'InfluencingCircle',
      assertivenessPicture: 'AssertivenessInfluencing',
      link: 'https://player.vimeo.com/video/464685035',
      pText: {
        en: 'Assertive with an',
        es: 'Asertivo con un',
        pt: 'Assertivo com um'
      },
      h1Text: {
        en: 'INFLUENCING STYLE',
        es: 'ESTILO DE INFLUENCING',
        pt: 'ESTILO DE INFLUENCING'
      }
  },
  {
      cStyleName: 'examining',
      cStyleCircle: 'ExaminingCircle',
      assertivenessPicture: 'AssertivenessExamining',
      link: 'https://player.vimeo.com/video/466234216',
      pText: {
        en: 'Assertive with an',
        es: 'Asertivo con un',
        pt: 'Assertivo com um'
      },
      h1Text: {
        en: 'EXAMINING STYLE',
        es: 'ESTILO EXAMINING',
        pt: 'ESTILO DE EXAMINING'
      }
  },
  {
      cStyleName: 'delivering',
      cStyleCircle: 'DeliveringCircle',
      assertivenessPicture: 'AssertivenessDelivering',
      link: 'https://player.vimeo.com/video/464684514',
      pText: {
        en: 'Assertive with a',
        es: 'Asertivo con un',
        pt: 'Assertivo com um '
      },
      h1Text: {
        en: 'DELIVERING STYLE',
        es: 'ESTILO DE DELIVERING',
        pt: 'ESTILO DE DELIVERING'
      }
  }
]