import React from "react";
import "./style.css";
import { ReactComponent as Arrow } from "@images/arrow-button.svg";

const PaginationControls = ({
    currentPage,
    maxPages,
    next,
    prev,
    className,
    skip,
}) => {
    return (
        <div
            className={`pagination__controls pagination__controls--${className}`}
        >
            <div className="pagination__controls__arrows">
                <button disabled={currentPage === 0} onClick={prev}>
                    <Arrow />
                </button>
                <button disabled={currentPage === maxPages - 1} onClick={next}>
                    <Arrow />
                </button>
            </div>
            {skip && (
                <div className="pagination__controls__skip" onClick={skip}>
                    Skip
                </div>
            )}
            <p>
                <span>{currentPage + 1}</span>/{maxPages}
            </p>
        </div>
    );
};

export default PaginationControls;
