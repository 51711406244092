import React from "react";
import "./style.css";
import { ReactComponent as Logo } from "@images/logo-full.svg";
import Background from "@images/background.svg";

const LoadingScreen = () => {
    return (
        <div
            className="loading-screen"
            style={{ backgroundImage: `url(${Background})` }}
        >
            <div className="loading-screen__logo">
                <Logo />
            </div>
            <h5>Change one thing. Improve everything.</h5>
        </div>
    );
};

export default LoadingScreen;
