import SectionWithBadge from "@components/SectionWithBadge";
import PageWrapper from "@components/PageWrapper";
import { useDispatch, useSelector } from "react-redux";
import AppSlice from "@store/app";
import React, { useEffect, useState } from "react";
import "./style.css";
import CertificatesTile from "@components/CertificatesTile";
import { Link } from "react-router-dom";
import { useFirestoreConnect } from "react-redux-firebase";
import { getFirstAttempt } from "@utils/getAttempts";
import { useTranslation } from "react-i18next";

const Certifications = () => {
    const dispatch = useDispatch();

    const userId = useSelector((state) => state.firebase.auth.uid);
    const { t, i18n } = useTranslation();
    const language = i18n.language;
    const [certifiedAttempts, setCertifiedAttempts] = useState([]);

    useFirestoreConnect([{ collection: "courses" }]);

    const allCourses = useSelector((state) => state.firestore.data?.courses);

    useEffect(() => {
        
        (async () => {
            const attempts = await getFirstAttempt(userId);
            const certs = attempts.filter((el) => el.certification);
            setCertifiedAttempts(certs);
        })();
     

        return () => setCertifiedAttempts([]);
    }, [userId]);

    const showCertification = (timeValid, courseName) => {
        dispatch(
            AppSlice.actions.setModal({
                visible: true,
                content: {
                    type: "certificate",
                    data: { courseName: courseName, timeValid: timeValid },
                },
            })
        );
    };

    return (
        <div className="certifications">
            <PageWrapper>
                <Link to="/">Dashboard</Link>
                <SectionWithBadge
                    title={t("certificates.pageHeader")}
                    badge={certifiedAttempts && certifiedAttempts.length}
                    noHr
                >
                    {certifiedAttempts.length > 0 && (
                        <p className="larger">
                            {t("certificates.mainHeader.firstPartText")}
                            {certifiedAttempts.length} 
                            {t("certificates.mainHeader.secondPartText")}
                        </p>
                    )}
                </SectionWithBadge>
                {certifiedAttempts
                    ? certifiedAttempts.length > 0
                        ? certifiedAttempts.map((attempt, i) => {
                              const courseData =
                                  allCourses?.[
                                      attempt.certification?.courseRef
                                          ?.split("/")
                                          .slice(-1)[0]
                                  ];
                              return (
                                  <React.Fragment key={i}>
                                      <CertificatesTile
                                          onClick={() =>
                                              showCertification(
                                                  attempt.certification
                                                      ?.timeValid,
                                                  courseData.name[language]
                                              )
                                          }
                                          courseName={courseData.name[language]}
                                          timeReceived={
                                              attempt.certification
                                                  ?.timeReceived
                                          }
                                          timeValid={
                                              attempt.certification?.timeValid
                                          }
                                      />
                                  </React.Fragment>
                              );
                          })
                        : null
                    : "Loading"}
            </PageWrapper>
        </div>
    );
};

export default Certifications;
