import seedrandom from "seedrandom";

export default function preprocess(json, language) {
    try {
        json = JSON.parse(json);
    } catch {}

    const seed = (json && json.seed) || Math.random();

    json.seed = seed;

    const seededRandom = seedrandom(seed);

    const pages = (json && json.pages) || [];

    for (let i = 0; i < pages.length; i++) {
        const page = pages[i];
        if (page.randomSelect) {
            if (page.randomSelectCount) {
                const randomQuestions = [];
                const randomIndexesQuestions = [];
                while (randomQuestions.length < page.randomSelectCount) {
                    const randomIndex = Math.round(
                        (page.elements.length - 1) * seededRandom()
                    );

                    if (randomIndexesQuestions.indexOf(randomIndex) === -1) {
                        randomIndexesQuestions.push(randomIndex);
                        const el = page.elements[randomIndex];
                        if (page.randomProp) {
                            el.comment = page.randomProp; //this needs to be reviewed 
                            el.axis = page.randomProp;
                        }
                        randomQuestions.push(el);
                    }
                }

                const newPages = randomQuestions.map((q, index) => ({
                    name: `page.name-${index}`,
                    title: page.title || "",
                    description: page.description || "",
                    elements: [q],
                }));

                pages.splice(i, 1, ...newPages);
                i += newPages.length - 1;
            }
        }
    }

    // Because of SurveyJS bug, an array of options with value/text fields
    // cannot be reorderded and needs to be tranformed into an array of strings.

    // NOTE: Later edit 05/Nov/2021: This bug was fixed in a recent survey update so I will comment out this code as it is not necesarry and it bloks us from using multilingual in ranking surveys
    /*
    for (let i = 0; i < pages.length; i++) {
        const page = pages[i];
        if (page.elements?.[0]?.type === "ranking") {
            // // TODO: Is the following commented out code needed still?
            // Store original choices for ranking question
            // under .originalChoices field (needs to be registered)
            // see (src/components/SurveyComponent/customWidgets.js)
            // Survey.JsonObject.metaData.addProperties("ranking", [
            //   { name: "originalChoices", default: {} },
            //           ]);
            page.elements[0].originalChoices = JSON.parse(
                JSON.stringify(page.elements[0].choices)
            );

            page.elements[0].choices = page.elements[0].choices.map(
                (e) => e.text
            );
        }
    }
    */

    return json;
}
