import React, { useCallback } from "react";
import { TimeProgressBar } from "@components/ProgressBar";
import getPodcastStatus from "@utils/getPodcastStatus";
import { useHistory, useParams } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { useFirestoreConnect } from "react-redux-firebase";
import cx from "classnames";
import Image from "@components/Image";
import { useTranslation } from "react-i18next";

import "./style.css";
import timeFormat from "@utils/timeFormat";
import PlayPauseButton from "@components/PlayPauseButton";
import AppSlice from "@store/app";
import { analyticsLogEvent } from "@components/Analytics";

const PodcastTile = ({
    podcast,
    podcastId,
    className,
    children,
    isDetail,
    hideRelatedCourse,
    ...rest
}) => {
    const {
        isInProgress,
        // isFuture,
        // isCompleted,
        duration,
        time,
    } = getPodcastStatus(podcast);

    const language = useTranslation().i18n.language;

    useFirestoreConnect([
        { collection: "courses" },
        { collection: "podcasts" },
    ]);

    const { podcastId: podIdFromParams } = useParams();
    const player = useSelector((state) => state.app.player);
    const dispatch = useDispatch();

    const pid = podcastId || podIdFromParams;

    const relatedCourse = useSelector(
        (state) =>
            state.firestore.data.courses?.[
                state.firestore.data.podcasts?.[pid].courseRef
            ]
    );

    const podcastDefinition = useSelector(
        (state) => state.firestore.data?.podcasts?.[pid]
    );

    const history = useHistory();

    const goToDetail = useCallback(
        (e) => history.push(`/podcast/${podcastId}`),
        [history, podcastId]
    );

    const isInPlayer = player.id === pid;
    const isCurrentlyPlaying = player.isPlaying && isInPlayer;

    return (
        <div
            className={cx(`podcast-tile`, className, {
                "horizontal-scroll-item": !isDetail,
                clickable: !isDetail,
            })}
            {...rest}
            onClick={isDetail === true ? null : goToDetail}
        >
            <div className="podcast-tile__item">
                <div
                    className={cx(`podcast-tile__image`, {
                        "podcast-tile__image--is-detail": isDetail,
                    })}
                >
                    <Image src={podcastDefinition?.image} alt="Podcast cover" />
                    <PlayPauseButton
                        isPlaying={isCurrentlyPlaying}
                        onClick={(e) => {
                            analyticsLogEvent('play_podcast', {name: podcastDefinition?.title})
                            dispatch(
                                AppSlice.actions.setPlayer({
                                    id: pid,
                                    isPlaying: isInPlayer
                                        ? !player.isPlaying // toggle if in player currently
                                        : true, // start playback otherwise,
                                    isVisible: !(
                                        isInPlayer && player.isPlaying
                                    ),
                                })
                            );
                            e.stopPropagation();
                        }}
                    />
                </div>

                <h5 className={`podcast-tile__title`}>{podcast?.title}</h5>

                {!hideRelatedCourse && relatedCourse && (
                    <div
                        className={cx(`podcast-tile__badge`, {
                            "podcast-tile__badge--is-detail": isDetail,
                        })}
                    >
                        {relatedCourse?.name[language]}
                    </div>
                )}
            </div>
            {!isDetail && isInProgress && (
                <TimeProgressBar
                    className={"podcast-tile__progress-bar"}
                    value={time}
                    maxValue={duration}
                    time
                />
            )}
            {!isDetail && !isInProgress && (
                <div className={`podcast-tile__duration-info`}>
                    {timeFormat(duration)}
                </div>
            )}
        </div>
    );
};

export default PodcastTile;
