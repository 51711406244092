import React from "react";
import "./style.css";
import cx from "classnames";
import { useSelector } from "react-redux";

const ActionPanel = ({
    className,
    children,
    visible,
    forPlayer,
    minified,
    onClick = () => {},
    ...rest
}) => {
    const player = useSelector((state) => state.app.player);

    return (
        <div
            className={cx("action-panel", className, {
                "action-panel--visible": visible,
                "action-panel--player-is-visible":
                    !forPlayer && player.isPlaying && player.id,
                "action-panel--for-player": forPlayer,
                "action-panel--for-player--expanded": forPlayer && !minified,
            })}
            onClick={onClick || (() => {})}
        >
            {children}
        </div>
    );
};

export default ActionPanel;
