import React, { useEffect, useState } from "react";
import "./style.css";

const Image = (props) => {
    const { src, alt } = props;

    const [sourceLoaded, setSourceLoaded] = useState(false);

    useEffect(() => {
        if (src) {
            setSourceLoaded(true);
        }
    }, [src]);

    return (
        <>
            <img
                className="custom-img"
                src={sourceLoaded ? src : null}
                alt={alt}
            />
        </>
    );
};

export default Image;
