import React, {
    useEffect,
    useState,
    useCallback,
    useMemo,
    /*useRef,*/
} from "react";
import "./style.css";
import * as Survey from "survey-react";
import "survey-react/survey.css";
import { useParams, useHistory } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";

import { useFirestore, useFirestoreConnect } from "react-redux-firebase";
import preprocessJson from "@utils/preprocessJson";
import ProgressBar from "@components/ProgressBar";
import "./style.css";
import AppSlice from "@store/app";
import PageWrapper from "@components/PageWrapper";
import { ReactComponent as CloseButton } from "@images/close.svg";
import {
    onUpdatePageCssClasses,
    onUpdateQuestionCssClasses,
} from "@utils/setSurveyCss";

import "./customWidgets";
import cx from "classnames";
//import Vimeo from "@vimeo/player";
import { hasAttempt } from "@utils/getAttempts";
import useGenerateAssertiveResults from "@hooks/useGenerateAssertiveResults";
import useGenerateAndStoreResults from "@hooks/useGenerateCStyleResults";
import firebase from "firebase/app";
import useCalculatePWCResults from "@hooks/useCalculatePWCResults";
import ShowCompletedScience from "@components/ShowCompletedScience/ShowCompletedScience";
import { useTranslation } from "react-i18next";

const SurveyComponent = (props) => {
    const { courseId } = useParams();
    const firestore = useFirestore();
    const dispatch = useDispatch();
    const history = useHistory();
    const { t, i18n } = useTranslation();

    const userId = useSelector((state) => state.firebase.auth.uid);
    const userCoursesCollection = `users/${userId}/userCourses`;
    const courseToUpdate = useMemo(
        () => ({
            collection: `${userCoursesCollection}`,
            doc: `${courseId}`,
        }),
        [userCoursesCollection, courseId]
    );
    const courseCategory = useSelector((state) => state.firestore?.data?.currentCourse?.category);
    const [userCourse, setUserCourse] = useState();
    const [json, setJson] = useState();
    const [surveyModel, setSurveyModel] = useState();
    const [localProgress, setLocalProgress] = useState();
    const [stepsArray, setStepsArray] = useState([]);
    const [numberOfSteps, setNumberOfSteps] = useState(0);
    const [hasUserInputs, setHasUserInputs] = useState(true);
    const [surveyLastStep, setSurveyLastStep] = useState(0);

    const generateAssertivenessResults = useGenerateAssertiveResults();
    const calculatePWResults = useCalculatePWCResults();
    const generateCStyleResults = useGenerateAndStoreResults();

    const getScrolled = () => {
        const body = document.body;

        // const scrollPercent =
        //     ((body.clientHeight + body.scrollTop) / body.scrollHeight) * 100;

        const scrollRemaining =
            body.scrollHeight - (body.clientHeight + body.scrollTop);

        // assume scrolled if only 100px to scroll is remaining at the beginning
        if (body.scrollTop === 0 && scrollRemaining < 100) {
            return true;
        }

        // moto G5 ugly fix...
        const threshold = 60;

        return (
            Math.abs(body.scrollHeight - body.scrollTop - body.clientHeight) <
            threshold
        );
    };

    const [scrolled, setScrolled] = useState(false);

    //const videoMissing = useRef(0);
    //const [videosCompleted, setVideosCompleted] = useState(true);

    const [completed, setCompleted] = useState();

    useFirestoreConnect([
        {
            collection: "courses",
            doc: courseId,
            storeAs: "currentCourse",
        },
        {
            collection: "users",
            doc: userId,
            subcollections: [{ collection: "userCourses", doc: courseId }],
            storeAs: "myCurrentCourse",
        },
        {
            collection: "users",
            doc: userId,
            subcollections: [
                { collection: "userCourses", doc: courseId },
                { collection: "attempts" },
            ],
            storeAs: "myAttempts",
        },
    ]);

    const courseName = useSelector(
        (state) => state.firestore.data?.currentCourse?.name[i18n.language]
    );
    const englishCourseName = useSelector(state => state.firestore.data?.currentCourse?.name['en'])

    const isMain = useSelector(
        (state) => state.firestore.data?.currentCourse?.isMain
    );
    const isAssertiveness = useSelector(
        (state) => state.firestore.data?.currentCourse?.isAssertiveness
    );

    const myCurrentCourse = useSelector(
        (state) =>
            state.firestore.status?.requested?.myCurrentCourse &&
            state.firestore.data?.myCurrentCourse
    );

    const myCStyle = useSelector(state => {
        const currentResults = state.firestore.data?.myCstyle?.results?.cStyle?.cStyleName;
        const previousResults = state.app.lastCompletedCStyle?.results?.cStyle?.cStyleName;
        
        if (currentResults) return currentResults;
        else if (previousResults) return previousResults;
        else return '';
    })
    
    useEffect(() => {
        dispatch(AppSlice.actions.setHeader({ visible: false }));
        return () => {
            dispatch(
                AppSlice.actions.setHeader({
                    visible: true,
                    showHamburger: true,
                })
            );
        };
    }, [dispatch]);

    const headerVisible = useSelector((state) => state.app.header.visible);

    useEffect(() => {
        const checkEnabled = async () => {
            const course = myCurrentCourse;

            let enabled;

            if (course) {
                if (typeof course.enabled === "string") {
                    enabled = await hasAttempt(
                        userCoursesCollection,
                        course.enabled
                    );
                } else {
                    enabled = course.enabled;
                }

                if (enabled) {
                    setUserCourse(course);
                } else {
                    setUserCourse(false);
                }
            }
        };

        if (!userCourse) {
            checkEnabled();
        }
    }, [myCurrentCourse, userCoursesCollection, userCourse]);

    useEffect(() => {
        const onScroll = () => !scrolled && setScrolled(getScrolled());

        document.body.addEventListener("scroll", onScroll);

        return () => {
            document.body.removeEventListener("scroll", onScroll);
        };
    }, [scrolled]);

    const handleDisabledButtons = useCallback((survey, options) => {
        var nextButton = document.querySelector(".custom-sv-next");
        var completeButton = document.querySelector(".custom-sv-complete");
        var scrolled = document.querySelector(
            ".survey-component__survey.scrolled"
        );
        /*
        var videosCompleted = document.querySelector(
            ".survey-component__survey.videosCompleted"
        );
        */

        const questions = survey.currentPage.questions;

        const disabled = questions.some(
            (q) =>
                (q.isRequired && !q.isAnswered) ||
                !scrolled /*|| !videosCompleted*/
        );

        if (disabled) {
            if (nextButton) {
                nextButton.disabled = true;
                nextButton.classList.add("custom-sv-next--disabled");
            } else if (completeButton) {
                completeButton.disabled = true;
                completeButton.classList.add("custom-sv-complete--disabled");
            }
        } else {
            if (nextButton) {
                nextButton.disabled = false;
                nextButton.classList.remove("custom-sv-next--disabled");
            } else if (completeButton) {
                completeButton.disabled = false;
                completeButton.classList.remove("custom-sv-complete--disabled");
            }
        }
    }, []);

    /*
    useEffect(() => {
        const videos = [
            ...document.querySelectorAll(".survey-component__survey iframe"),
        ];

        console.log("video effects");

        videos.forEach((iframe) => {
            if (!iframe.player && iframe.src.match(/vimeo/)) {
                console.log("video wired");
                const player = new Vimeo(iframe);

                videoMissing.current++;
                setVideosCompleted(false);

                const onEnded = () => {
                    player.off("ended", onEnded);
                    videoMissing.current--;
                    if (videoMissing.current === 0) {
                        setVideosCompleted(true);
                    }
                };

                player.on("ended", onEnded);

                iframe.player = player;
            }
        });
    });
    */

    //we want to call the function when scrolled changes or videoCompleted changes, even if it's not a dependancy variable
    useEffect(() => {
        if (surveyModel) {
            if (!scrolled && getScrolled()) {
                setScrolled(true);
            }
            handleDisabledButtons(surveyModel);
        }
    }, [
        handleDisabledButtons,
        surveyModel,
        surveyModel && surveyModel.currentPageNo,
        scrolled /*, videosCompleted*/,
    ]);

    const orderJson = (json) => {
        const cstyle = myCStyle;

        if (!cstyle) {
            return json;
        }

        const jsonCopy = Object.assign({}, json);

        const pagesCopy = [...jsonCopy.pages];

        const startIndex = pagesCopy.findIndex(
            (p) => p.elements[0].name === "connecting"
        );

        if (startIndex === -1) {
            return json;
        }

        const pagesToSort = pagesCopy.slice(startIndex, startIndex + 4);

        const sortedPages = pagesToSort.sort((a, b) =>
            a.elements[0].name === cstyle ? -1 : 0
        );

        pagesCopy.splice(startIndex, 4, ...sortedPages);

        jsonCopy.pages = pagesCopy;

        return jsonCopy;
    };

    const getSteps = (json) => {
        const pages = json.pages;
        let lastStep = 0;
        let totalSteps = pages.reduce((acc, page, index) => {
            if(page.elements[0].type !== 'html') {
                lastStep = index;
                return Number(acc + 1);
            }
            return acc;
        }, 0);
        setSurveyLastStep(lastStep);
        let currentStep = 0;
        let arraySteps = [];
        let percentage = 0;
        let isStep = false;

        pages.forEach((page, index) => {
            if(page.elements[0].type !== 'html') {
                currentStep++;
                isStep = true;
                if(currentStep === 1) {
                    arraySteps.push({currentStep, percentage, isStep});
                } else {
                    percentage = (((currentStep - 1) / (totalSteps)) * 100).toFixed(0); 
                    arraySteps.push({currentStep, percentage, isStep});
                }
            } else {
                if(index === lastStep + 1) {
                    isStep = true;
                    percentage = ((currentStep / (totalSteps)) * 100).toFixed(0);
                    arraySteps.push({currentStep, percentage, isStep});

                } else {
                    isStep = false;
                    arraySteps.push({currentStep, percentage, isStep});
                }
            }
        })

        setNumberOfSteps(totalSteps);
        setStepsArray(arraySteps);

    }

    useEffect(() => {
        let progress = {};
        const saveProgress = (survey) => {
            progress = {
                ...progress,
                results: {
                    ...progress.results,
                    currentPageNo: survey.currentPageNo,
                    data: survey.data,
                },
            };
            if (hasUserInputs && survey.currentPageNo === surveyLastStep + 1 && surveyLastStep !== 0) {
                //write results to database
                if (englishCourseName === 'Finding your c-style') {
                    const cStyle = generateCStyleResults(survey);
                    progress.results.cStyle = cStyle;
                }
                if (englishCourseName === 'Building assertiveness') {
                    const { scores, percents } = generateAssertivenessResults(survey);
                    progress.results.scores = scores;
                    progress.results.percents = percents;
                }
                if (englishCourseName === 'Presenting in a second language') {
                    const values = calculatePWResults(survey, 'survey-component');
                    progress.results.values = values;
                }
            }
            setLocalProgress(progress);
            firestore
                .update(courseToUpdate, progress)
                .catch((e) => console.log(e));
        };

        const onCurrentPageChanged = (survey, options) => {
            document.body.scrollTo(0, 0);
            setScrolled(false);
            saveProgress(survey);
            const totalPages = survey.pageCount;

            const analytics = firebase && firebase.analytics;
            if (typeof analytics === "function") {
                analytics().logEvent("course_page_view", {
                    name: englishCourseName,
                    page: survey.currentPageNo,
                });
            }

            if (
                isMain &&
                options.isNextPage &&
                survey.currentPageNo === totalPages - 1
            ) {
                survey.doComplete();
            }

            if (
                isAssertiveness &&
                options.isNextPage &&
                survey.currentPageNo === totalPages - 4
            ) {
                survey.doComplete();
            }
        };

        const onComplete = () => {
            firestore
                .update(courseToUpdate, { 
                    isTaken: true,
                    timeCompleted: Date.now() 
                })
                .then(async () => {
                    const hasPreviousAttempt = await hasAttempt(
                        userCoursesCollection,
                        courseToUpdate.doc
                    );

                    if (!hasPreviousAttempt) {
                        if (!isMain) {
                            firestore
                                .get({
                                    collection: `courses`,
                                    doc: `${courseId}`,
                                })
                                .then((doc) => {
                                    const courseName = doc.data().name[i18n.language];
                                    setTimeout(() => {
                                        dispatch(
                                            AppSlice.actions.setModal({
                                                visible: false,
                                                content: {
                                                    type: "certificate",
                                                    data: {
                                                        courseName: courseName,
                                                        timeValid: new Date().setFullYear(
                                                            new Date().getFullYear() +
                                                                1
                                                        ),
                                                    },
                                                },
                                            })
                                        );
                                        // See also delay in LevelListener
                                    }, 1500);
                                });
                        } else {
                            //
                        }
                    }
                })
                .then(() => {
                    setCompleted(true);
                    const analytics = firebase && firebase.analytics;
                    if (typeof analytics === "function") {
                        analytics().logEvent("course_completed", {
                            name: englishCourseName,
                        });
                    }
                })

                .catch((e) => console.log(e));
        };

        const prepareModel = (course) => {
            const orderedJson = orderJson(course.json);
            
            if (!orderedJson.pages[0].hasUserInputs) setHasUserInputs(false);
            else getSteps(orderedJson);

            const model = new Survey.Model(orderedJson);
            model.onComplete.add(onComplete);
            model.data = course.results?.data || {};
            model.currentPageNo = course.results?.currentPageNo || 0;
            model.onUpdateQuestionCssClasses.add(onUpdateQuestionCssClasses);
            model.onUpdatePageCssClasses.add(onUpdatePageCssClasses);
            model.onCurrentPageChanged.add(onCurrentPageChanged);
            model.onAfterRenderPage.add(handleDisabledButtons);
            model.onValueChanged.add(handleDisabledButtons);
            model.showQuestionNumbers = "off";
            model.requiredText = "";
            model.completedHtml = "<p></p>";
            model.locale = i18n.language;

            const previousProgress = {
                results: {
                    currentPageNo: model?.currentPageNo,
                    data: model?.data,
                },
            };

            setLocalProgress(previousProgress);

            return model;
        };
        if (userCourse) {
            if (userCourse.timeCompleted !== null) {
                // setCompleted(true);
            } else if (userCourse.timeStarted) {
                setJson(userCourse.json);
                const model = prepareModel(userCourse);
                setSurveyModel(model);
            } else {
                firestore
                    .get({
                        collection: `courses`,
                        doc: `${courseId}`,
                    })
                    .then((doc) => {
                        const processedJson = preprocessJson(doc.data().json, i18n.language);
                        setJson(processedJson);
                        setSurveyModel(prepareModel({ json: processedJson }));
                    });
            }
        }
    }, [
        surveyLastStep,
        userCourse,
        firestore,
        courseId,
        dispatch,
        courseToUpdate,
        isMain,
        handleDisabledButtons,
        setScrolled,
    ]);

    // save user json on first access to the course
    useEffect(() => {
        if (json && userCourse && userCourse.timeStarted == null) {
            const processedJson = {
                json: json,
                timeStarted: Date.now(),
            };
            firestore
                .update(courseToUpdate, processedJson)
                .catch((e) => console.log(e));
        }
    }, [json, userCourse, firestore, courseToUpdate]);

    // determine number of pages with questions
    const pages = (json?.pages || []).filter(
        // only pages with named elements (questions) are taken into account
        (page) => !!page.elements?.some((el) => el.name)
    ).length;
    
    const currentPage = hasUserInputs ? localProgress?.results.currentPageNo : 
         localProgress?.results.currentPageNo === 0 
         ? localProgress?.results.currentPageNo
            : localProgress?.results.currentPageNo + 1;
    //const answered = Object.keys(localProgress?.results?.data || {}).length;

    //if is assertiveness we need to cut last three pages
    const percentage = ((currentPage / pages) * 100).toFixed(0);

    const goBack = useCallback(
        (e) => {
            if (courseCategory === 'science') {
                history.push('/');
            } else {
                history.goBack();
            }
        },
        [history]
    );

    const goToTopics = useCallback(
        (e) => {
            history.push("/courses");
        },
        [history]
    );

    const goToCStyleResults = useCallback(
        (e) => {
            history.push("/c-style");
        },
        [history]
    );

    const goToAssertivenessResults = useCallback(
        (e) => {
            history.push("/assertiveness-results");
        },
        [history]
    );

    return (
        <div className="survey-component">
            <PageWrapper>
                {userCourse === undefined ? (
                    !headerVisible && (
                        <div className="survey-component__progress">
                            <div className="survey-component__progress__heading">
                                <h4>{courseName}</h4>
                                <CloseButton onClick={goBack} />
                            </div>
                            {   (stepsArray[currentPage]?.isStep || !hasUserInputs) &&
                                <ProgressBar
                                    percentage={stepsArray[currentPage]?.percentage}
                                    value={stepsArray[currentPage]?.currentStep}
                                    maxValue={numberOfSteps}
                                    showPercentage={pages !== 0}
                                    showValue={pages !== 0}
                                    isCoursePage
                                />
                            }
                        </div>
                    )
                ) : userCourse === false ? (
                    <h1>This course is not accessible.</h1>
                ) : (
                    <>
                        <div className="survey-component__progress">
                            <div className="survey-component__progress__heading">
                                <h4>{courseName}</h4>
                                <CloseButton onClick={goBack} />
                            </div>
                            {   (stepsArray[currentPage]?.isStep || !hasUserInputs) &&
                                <ProgressBar
                                    percentage={hasUserInputs ? stepsArray[currentPage]?.percentage : percentage}
                                    value={ hasUserInputs 
                                                ? stepsArray[currentPage]?.currentStep 
                                                : currentPage === 0 ? 1 : currentPage
                                    }
                                    maxValue={hasUserInputs ? numberOfSteps : pages}
                                    showPercentage={pages !== 0}
                                    showValue={pages !== 0}
                                    isCoursePage
                                />
                            }
                        </div>
                        <div
                            className={cx("survey-component__survey", {
                                scrolled /*,
                                videosCompleted,*/,
                            })}
                        >
                            {surveyModel && (
                                <Survey.Survey model={surveyModel} />
                            )}
                            {completed && courseCategory !== 'science' &&
                                (<div className="survey-component__survey__thanks-page">
                                    <div className="survey-component__survey__thanks-page__background" />
                                    <h1>
                                        {(json && json.thankYouText[i18n.language]) ||
                                            "Thank you for completing the course!"}
                                    </h1>

                                    <button
                                        className="button-primary"
                                        onClick={
                                            isMain
                                                ? goToCStyleResults
                                                : isAssertiveness 
                                                    ? goToAssertivenessResults
                                                    : goToTopics
                                        }
                                    >
                                        {isMain
                                            ? t('surveyComponents.completedText.seeCStyleResults')
                                            : isAssertiveness 
                                                ? t('surveyComponents.completedText.seeResults') 
                                                : t('surveyComponents.completedText.goToCourses')
                                        }
                                    </button>
                                </div>
                            )}

                            {completed && courseCategory === 'science' &&
                                <ShowCompletedScience 
                                    thankYouText={json.thankYouText}
                                    courseName={courseName}
                                />
                            }
                        </div>
                    </>
                )}
            </PageWrapper>
        </div>
    );
};

export default SurveyComponent;
