import { useSelector } from "react-redux";
import { useFirestoreConnect, isLoaded } from "react-redux-firebase";
import getCourseStats from "@utils/getCourseStatus";

const useGroupedCoursesList = (userId) => {
    useFirestoreConnect([
        {
            collection: "users",
            doc: userId,
            subcollections: [{ collection: "userCourses" }],
            storeAs: "myCourses",
        },
        { collection: "courses" },
    ]);

    const allCourses = useSelector((state) => state.firestore.data.courses);

    const courses = useSelector((state) => {
        const userCoursesData = state.firestore.ordered.myCourses || [];

        const orderedCourses = userCoursesData.map((c) => {
            let { enabled, ...rest } = c;

            // if  enabled is a course id, check if that corse has been completed to enable the current course
            if (typeof enabled === "string") {
                enabled =
                    (userCoursesData[enabled] &&
                        !!userCoursesData[enabled].timeCompleted) ||
                    false;
            }

            return { enabled, ...rest };
        });

        orderedCourses.sort((a, b) => {
            if (a.enabled && !b.enabled) {
                return -1;
            } else if (!a.enabled && b.enabled) {
                return 1;
            } else {
                return 0;
            }
        });

        return orderedCourses;
    });

    if (isLoaded(allCourses))
        return courses.reduce(
            (acc, item) => {
                const stats = getCourseStats(item);
                item.title = allCourses?.[item.id]?.name;
                item.description = allCourses?.[item.id]?.description;
                item.stats = stats;
                item.image = allCourses?.[item.id]?.image;
                item.category = allCourses?.[item.id]?.category;

                if (stats.isInProgress) {
                    acc.inProgress.push(item);
                } else if (stats.isFuture) {
                    acc.future.push(item);
                } else if (stats.isCompleted) {
                    acc.completed.push(item);
                }

                return acc;
            },
            {
                inProgress: [],
                future: [],
                completed: [],
            }
        );
};

export default useGroupedCoursesList;
