import React, { useState } from "react";
import "./style.css";
import Collapse from "@kunukn/react-collapse";
import cx from "classnames";

const Collapsible = ({ children, className, ...props }) => {
    const [isOpen, setIsOpen] = useState(props.isOpen || false);
    return (
        <div className={cx("collapsible", className)}>
            <Collapse
                isOpen={isOpen}
                onClick={() => setIsOpen((open) => !open)}
                addState
                {...props}
            >
                {children}
            </Collapse>
        </div>
    );
};

export default Collapsible;
