import { useEffect } from "react";
import { useFirestoreConnect } from "react-redux-firebase";
import { useDispatch, useSelector } from "react-redux";
import usePrevious from "src/hooks/usePrevious";
import AppSlice from "@store/app";

const LevelListener = () => {
    const userId = useSelector((state) => state.firebase.auth.uid);
    useFirestoreConnect([{ collection: "users", doc: userId }]);
    const dispatch = useDispatch();

    const userLevel = useSelector(
        (state) => state.firestore.data.users?.[userId]?.level
    );
    const previousLevel = usePrevious(userLevel);

    const cstyleCertificateViewed = useSelector(
        (state) => state.app.cstyleCertificateViewed
    );

    useEffect(() => {
        if (userLevel > previousLevel) {
            if (userLevel === 2) {
                return;
            } else {
                setTimeout(() => {
                    dispatch(
                        AppSlice.actions.setModal({
                            visible: true,
                            content: {
                                type: "unlockBadge",
                                data: {
                                    level: userLevel,
                                },
                            },
                        })
                    );

                    // see also delay in SurveyComponent after Certification
                }, 8500);
            }
        } else {
            if (userLevel === 2 && cstyleCertificateViewed) {
                setTimeout(() => {
                    dispatch(
                        AppSlice.actions.setModal({
                            visible: true,
                            content: {
                                type: "unlockBadge",
                                data: {
                                    level: userLevel,
                                },
                            },
                        })
                    );

                    // see also delay in SurveyComponent after Certification
                }, 8500);
            }
        }
    }, [userLevel, previousLevel, dispatch, cstyleCertificateViewed]);

    // don't render anything
    return null;
};

export default LevelListener;
