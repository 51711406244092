//react 
import React from 'react';
import { useHistory, Link } from "react-router-dom";
import { useTranslation } from 'react-i18next';

//redux
import { useFirestoreConnect } from 'react-redux-firebase';
import { useSelector, useDispatch } from 'react-redux';
import AppSlice from "@store/app";

//hooks
import useTakeCourse from '@hooks/useTakeCourse';

//components
import CStyleBackground from '@components/CStyleBackground/CStyleBackground';
import PersonalisedVideo from '@components/PersonalisedVideo/PersonalisedVideo';
import RecommendedVideo from '@components/RecommendedVideo/RecommendedVideo';
import CustomVideoPlayer from '@components/Video/CustomVideoPlayer';
import PageSpinner from "@components/PageSpinner/PageSpinner";
import PageWrapper from '@components/PageWrapper';
import Graph from '@components/Graph/Graph';

//icons
import { ReactComponent as SendEmail } from "@assets/svg/EmailButton.svg";
import { ReactComponent as InvitePerson} from "@assets/svg/InviteButton.svg";

//styles
import './CStyleResults.css';

const CStyleResults = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { t, i18n } = useTranslation();
  const language = i18n.language;

  const takeCourse = useTakeCourse();
  const userId = useSelector((state) => state.firebase.auth.uid);
  const user = useSelector((state) => state.firestore.data?.users?.[userId]);

  useFirestoreConnect([
    {
      collection: "users",
      doc: userId,
      subcollections: [{ collection: "userCourses", doc: "FcFUxrbSW8bg6RoX1n2g" }],
      storeAs: "myCstyle",
    },
    {
      collection: "courses",
      where: [["name.en", "==", `Building assertiveness`]],
      storeAs: "AssertivenessCourse",
    }
  ]);
  
  const currentResults = useSelector(state => state.firestore.data?.myCstyle?.results?.cStyle);
  const previousResults = useSelector(state => state.app.lastCompletedCStyle?.results?.cStyle);
  const cStyleResults = currentResults || previousResults;
  const myCStyle = cStyleResults?.cStyleName;
  const value = cStyleResults?.coordinates || {};
  const assertivenessCourseId = Object.keys(useSelector(state => state.firestore?.data?.AssertivenessCourse) || [])[0];
  const shareFeatureEnabled = useSelector(state => state.app?.shareFeature);

  // avoid values near 0 not to be over the line
  const x = (Math.abs(value.x) * 0.9 + 0.1) * Math.sign(value.x);
  const y = (Math.abs(value.y) * 0.75 + 0.25) * Math.sign(value.y);

  const left = `${((x + 1) / 2) * 100}%`;
  const top = `${(1 - (y + 1) / 2) * 100}%`;

  const displayModal = (type) => {
    dispatch(AppSlice.actions.setModal({
      visible: true,
      content: { 
        type: type
      }
    }))
  }

  if (!currentResults && !previousResults) return <PageSpinner />
  return (
    <div className="cStyle-results-container">
      <PageWrapper>

        <div className="location-header">
          <div>
            <Link to="/">
              {t("resultsPages.bottomButtons.dash")}
            </Link>
            <span className="profile-c-style-span">{t("resultsPages.cStyle")}</span>
          </div>
                          
          <div className="retake-btn">
            <button
              className="button-primary button-primary--desktop  button-primary--remake"
              onClick={() => takeCourse(user?.mainCourse, 'take_course', 'Finding your C-Style')}
            >
              {t("resultsPages.buttons")}
            </button>
          </div>
        </div>  

        <div className="c-style-name-container">
          <CStyleBackground />

          <div className="style-text-container">
            <div> 
              <h6>{t("resultsPages.yourCStyle")}</h6>
              <h1>{t(`resultsPages.styles.${myCStyle}`)}</h1>
            </div>
          </div>
        </div>

        <p className='static-text'>
          {t("resultsPages.stylesIntro")}
        </p>

        <div className="main-zone-paragraph">
          <h1>{t("resultsPages.yourCStyle")}</h1>
          <p className="static-text">{cStyleResults?.mainText[language]}</p>
        </div>

        <Graph 
          left={left}
          top={top}
          leftX={t('graph.introvert')}
          rightX={t('graph.extrovert')}
          topY={t('graph.people')}
          bottomY={t('graph.facts')}
          showCStyleLabels={true}
        />

        <div className="personalised-paragraph-container">
          <p className="static-text">{cStyleResults?.personalisedText[language]}</p>
        </div>

        <div className="personalised-video-container page-width">
          <h1>{t('resultsPages.cStylePersonalisedVideo.header')}</h1>
          <p>{t('resultsPages.cStylePersonalisedVideo.paragraph')}</p>
          {
            cStyleVideos.map((video, index) => {
              if (video.cStyleName === myCStyle)
                return(
                  <PersonalisedVideo 
                    key={index}
                    src={video.link}
                    bgClass={video.cStyleName}
                  />
                )
              return null;
            })
          }
        </div>

        <div className="recommended-video-container">
          <h1>{t('resultsPages.recommendedVideo')}</h1>
          <div className="videos-container">
            {   
              cStyleVideos.map((video, index) => {
                if (video.cStyleName !== myCStyle) 
                  return (
                    <RecommendedVideo 
                      key={index}
                      circle={video.cStyleCircle} 
                      insidePicture={video.cStylePicture} 
                      videoLink={video.link}
                      h1Text={video.h1Text['en']}
                      pText={video.pText[language]}
                    />
                  )
                return null;
              })
            }
          </div>
        </div>

        <div className="bottom-btns-container page-width">
          <button 
            className="button-primary"
            onClick={() => { 
              takeCourse(assertivenessCourseId, 'take_course', 'Building assertiveness') }}
            >
            {t('resultsPages.bottomButtons.takeAssertiveness')}
          </button>
          <button 
            className="button-secondary"
            onClick={() => { history.push('/') }}
          >
            {t('resultsPages.bottomButtons.dash')}
          </button>
        </div>

      {shareFeatureEnabled == 'true' && <div className="customHR"></div>}

      {shareFeatureEnabled == 'true' &&
        <div className="email-container">
          <button 
            className="sendEmailBtn button-secondary"
            onClick={() => displayModal('shareResults')}
          >
            <SendEmail />
            <h1>Email</h1>				
          </button>
          <button 
            className="sendInviteBtn button-secondary"
            onClick={() => displayModal('inviteFriend')}
          >
            <InvitePerson />
            <h1>Invite</h1>
          </button>
        </div>	
      }	

      <div className="h30px"></div>

      {/* video player for c-style labels */}
      <CustomVideoPlayer />
        
      </PageWrapper>
    </div>
  )
}

export default CStyleResults

const cStyleVideos = [
  {
    cStyleName: 'connecting',
    cStyleCircle: 'ConnectingCircle',
    cStylePicture: 'ConnectingPicture',
    link: 'https://player.vimeo.com/video/461431074',
    pText: {
      en: 'Discover',
      es: 'Descubrir',
      pt: 'Descobrir'
    },
    h1Text: {
      en: 'CONNECTING',
      es: 'CONECTANDO',
      pt: 'CONNECTING'
    },
  },
  {
    cStyleName: 'influencing',
    cStyleCircle: 'InfluencingCircle',
    cStylePicture: 'InfluencingPicture',
    link: 'https://player.vimeo.com/video/461434024',
    pText: {
      en: 'Discover',
      es: 'Descubrir',
      pt: 'Descobrir'
    },
    h1Text: {
      en: 'INFLUENCING',
      es: 'INFLUENCIAR',
      pt: 'INFUENCIADOR'
    },
  },
  {
    cStyleName: 'examining',
    cStyleCircle: 'ExaminingCircle',
    cStylePicture: 'ExaminingPicture',
    link: 'https://player.vimeo.com/video/461433857',
    pText: {
      en: 'Discover',
      es: 'Descubrir',
      pt: 'Descobrir'
    },
    h1Text: {
      en: 'EXAMINING',
      es: 'EXAMINANDO',
      pt: 'EXAMINADOR'
    },
  },
  {
    cStyleName: 'delivering',
    cStyleCircle: 'DeliveringCircle',
    cStylePicture: 'DeliveringPicture',
    link: 'https://player.vimeo.com/video/464747408',
    pText: {
      en: 'Discover',
      es: 'Descubrir',
      pt: 'Descobrir'
    },
    h1Text: {
      en: 'DELIVERING',
      es: 'ENTREGANDO',
      pt: 'DELIVERING'
    },
  }
]