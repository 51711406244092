import React, { useEffect } from 'react'
import { v4 as uuid } from 'uuid';
import $ from 'jquery';
import './SI_ThirdPage.css'
import PageWrapper from '@components/PageWrapper';

const SI_ThirdPage = ({data}) => {
  

  useEffect(() => {
    $('.survey-component__survey .sv_main').addClass('overWriteSurvey');
    $('.survey-component__survey .sv_body').css('line-height', 'normal');

    return () => {
      $('.survey-component__survey .sv_main').removeClass('overWriteSurvey');
    }
  }, [])

  return (
    <div className="qna-container">
      <PageWrapper>
        <div className="qna-text-container">
            <h1>Get your answers ready</h1>
            <p>It’s never nice to be put on the spot with a tricky question. Read the section below to prepare in advance with solid answers to the top 10 scientific questions you might get asked.</p>
        </div>
        {
          data.map(q => {
            const keyId = uuid();
            return(
              <QnACardComponent 
                key={keyId}
                question={q.question}
                answer={q.answer}
              />
            )
          })
        }
      </PageWrapper>
    </div>
  )
}

export default SI_ThirdPage


// create cards component for this page
const QnACardComponent = ({question, answer}) => {
  return(
    <div className="qna-card-container">
      <div className="question-container">
        <h1><span>Q</span> - {question}</h1>
      </div>

      <div className="answer-container">
        <h1><span>A</span> - {answer}</h1>
      </div>
    </div>
  )
}