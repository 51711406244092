import { useCallback } from 'react';
import { useFirestore } from 'react-redux-firebase';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { analyticsLogEvent } from '@components/Analytics';

const useTakeCourse = () => {

  const firestore = useFirestore();
  const history = useHistory();
  const userId = useSelector(state => state.firebase.auth.uid);

  const takeCourse = useCallback(
    (courseId, status, courseName) => {
      let eventName = '';

      switch (status.toLowerCase()) {
        case 'go': 
          eventName = 'take_course';
          break;
        case 'continue':
          eventName = 'continue_course';
          break;
        case 'retake':
          eventName = 'retake_course';
          break;
        default: 
          eventName = 'start_course';
      }
      
      analyticsLogEvent(eventName, { course: courseName});

      firestore
        .update(
          {
              collection: `users/${userId}/userCourses`,
              doc: `${courseId}`,
          },
          {
              results: firestore.FieldValue.delete(),
              timeCompleted: null,
              timeStarted: null,
          }
        )
        .then(() => {
            history.push(`/course/${courseId}`);
        });
    },
    [history, firestore, userId]
);

  return takeCourse;
}

export default useTakeCourse
