import React from "react";
import "./style.css";
import classnames from "classnames";
import { useTranslation } from "react-i18next";

const Radio = ({ question, onSelected }) => {
    const choices = question?.choices;
    const { t } = useTranslation();

    //reverse array since Adam suggested to revert Agree - Disagree order.
    //could have done this or edit the course. this was the quick fix. 
    //perhaps when we have some spare time edit the courses and come back to normal order
    const reversedChoices = []
    for (let i = choices.length - 1; i > -1; i--) {
        reversedChoices.push(choices[i]);
    }

    return (
        <div className="radio">
            <h5 className={classnames({ selected: question.value })}>
                {question.value
                    ? choices.filter((c) => c.value === question.value)[0].text
                    : choices.length > 2 
                        ? t("surveyComponents.radioComponent.question")
                        : t("surveyComponents.radioComponent.questionWithTwoInputs")
                }
            </h5>
            <div className="radio__wrapper">
                <div className="radio__bar">
                    <div className="radio__choices">
                        {choices &&
                            reversedChoices.map((c, i) => (
                                <div
                                    key={i}
                                    className="radio__choices__dots-container"
                                >
                                    <div
                                        onClick={() => onSelected(c.value)}
                                        className={classnames(
                                            "radio__choices--dot",
                                            {
                                                "radio__choices--dot--selected":
                                                    question.value === c.value,
                                            }
                                        )}
                                    ></div>
                                    {(i === 0 || i === choices.length - 1) && (
                                        <span
                                            className="radio__choices__dots-container--label"
                                            data-index={i}
                                        >
                                            {c.text}
                                        </span>
                                    )}
                                </div>
                            ))}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Radio;
