import React, { useState } from "react";
import "./style.css";
import { ReactComponent as PlusIcon } from "@images/plus.svg";
import { ReactComponent as MinusIcon } from "@images/minus.svg";

export const categories = [
    { key: "passive", label: "passive" },
    { key: "passiveAggressive", label: "passive / aggressive" },
    { key: "aggressive", label: "aggressive" },
    { key: "assertive", label: "assertive" },
];

const AssertiveResultsChart = ({ value, children }) => {
    const [showDefinitions, setShowDefinitions] = useState(false);

    return (
        <div className="assertive-results-chart">
            <hr />
            <div className="assertive-results-chart__heading">
                <h3>Your results</h3>

                <div
                    className="assertive-results-chart__heading__toggle"
                    onClick={() => setShowDefinitions(!showDefinitions)}
                >
                    {showDefinitions ? <MinusIcon /> : <PlusIcon />}
                    <p>{showDefinitions ? "Hide" : "Discover more"}</p>
                </div>
            </div>

            {showDefinitions && (
                <div className="assertive-results-chart__definitions">
                    {children}
                </div>
            )}

            {categories.map(({ key, label }) => {
                return (
                    <React.Fragment key={key}>
                        <h4 className="assertive-results-chart__item-name">
                            {label}
                        </h4>
                        <div className="assertive-results-chart__item">
                            <div className="assertive-results-chart__item-bar-wrapper">
                                <div
                                    className="assertive-results-chart__item-bar"
                                    style={{
                                        width: `${value.percents[key]}%`,
                                    }}
                                />
                            </div>
                            <div className="assertive-results-chart__item-percent">
                                {value.percents[key]}%
                            </div>
                        </div>
                    </React.Fragment>
                );
            })}

            {/* <pre>{JSON.stringify(value, null, 2)}</pre> */}
        </div>
    );
};

export default AssertiveResultsChart;
