import React, { useEffect, useState } from 'react'
import { useParams, useHistory } from 'react-router-dom'
import firebase from 'firebase';

const CustomToken = () => {
  const history = useHistory();
  const { customToken } = useParams();
  const [customeTokenStatus, setCustomTokenStatus] = useState(false)

  useEffect(() => {

    firebase.auth().signOut()
    const loginCustomToken = async () => {
      if (customToken) {
        setCustomTokenStatus(true)
        await firebase.auth().signInWithCustomToken(customToken);
        history.push('/');
      }
    }

    loginCustomToken();
  }, [customToken, history])

  return customeTokenStatus ? <div></div>
  : (
      <div>
        Loading...
      </div>
    )
}

export default CustomToken;
