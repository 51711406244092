import React, { useCallback } from "react";
import ProgressBar from "@components/ProgressBar";
import getCourseStatus from "@utils/getCourseStatus";
import { useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { ReactComponent as PodcastIcon } from "@images/podcast.svg";
import { useFirestoreConnect } from "react-redux-firebase";
import AppSlice from "@store/app";
import Collapsible from "@components/Collapsible";
import { useTranslation } from 'react-i18next';

import "./style.css";
import Image from "@components/Image";
import timeFormat from "@utils/timeFormat";
import cx from "classnames";

const TopicsTile = ({
    course,
    topicId,
    isDetail,
    className,
    children,
    vertical,
    ...rest
}) => {
    const {
        isInProgress,
        isFuture,
        isCompleted,
        percentage,
        currentPage,
        pages,
    } = getCourseStatus(course);

    const language = useTranslation().i18n.language;

    const history = useHistory();
    const dispatch = useDispatch();

    const userId = useSelector((state) => state.firebase.auth.uid);

    useFirestoreConnect([
        {
            collection: "users",
            doc: userId,
            subcollections: [
                {
                    collection: "userCourses",
                    doc: topicId,
                    subcollections: [
                        {
                            collection: "attempts",
                            limit: 1,
                            orderBy: ["timeCompleted", "desc"],
                        },
                    ],
                },
            ],
            storeAs: "myAttempts",
        },
    ]);

    const lastAttempt = useSelector(
        (state) => state.firestore.ordered?.myAttempts
    );

    const courseDefinition = useSelector(
        (state) => state.firestore.data?.courses?.[topicId]
    );

    const goToDetail = useCallback(
        (e) => history.push(`/course-details/${course?.id}`),
        [history, course?.id]
    );

    const goToCourse = useCallback(
        (e) => history.push(`/course/${topicId || course?.id}`),
        [history, topicId, course?.id]
    );

    const onCertifiedClick = () => {
        dispatch(
            AppSlice.actions.setModal({
                visible: true,
                content: {
                    type: "certificate",
                    data: {
                        courseName: courseDefinition?.name[language],
                        timeValid: lastAttempt?.[0]?.certification?.timeValid,
                    },
                },
            })
        );
    };
    return (
        <div
            className={cx(`topics-tile`, className, {
                "horizontal-scroll-item": !isDetail,
            })}
            {...rest}
        >
            <div
                className={cx("topics-tile__item", {
                    "topics-tile__item--is-detail": isDetail,
                })}
            >
                {!isDetail && courseDefinition?.podcasts?.length && (
                    <div className="topics-tile__item__podcast">
                        <PodcastIcon onClick={() => {}} />
                    </div>
                )}
                <div
                    className={cx(`topics-tile__item__image`, {
                        "topics-tile__item__image--is-detail": isDetail,
                    })}
                    onClick={isDetail === true ? null : goToDetail}
                >
                    <Image src={courseDefinition?.image} alt="Course cover" />
                    {!isDetail && (
                        <h5 className={cx(`topics-tile__item__image__title`)}>
                            {courseDefinition?.name[language]}
                        </h5>
                    )}
                </div>
                <Collapsible
                    collapseHeight={75}
                    className={cx("topics-tile__item__description", {
                        "topics-tile__item__description--vertical": vertical,
                    })}
                >
                    <p className="small">{courseDefinition?.description[language]}</p>
                </Collapsible>
            </div>
            <div
                className={cx("topics-tile__progress", {
                    "topics-tile__progress--vertical": vertical,
                })}
            >
                {isDetail && isCompleted && (
                    <ProgressBar
                        className={"topics-tile__progress-bar"}
                        percentage={percentage}
                        showPercentage={false}
                        showCertified={true}
                        value={pages}
                        maxValue={pages}
                        onCertifiedClick={onCertifiedClick}
                    />
                )}
                {isInProgress && (
                    <ProgressBar
                        className={"topics-tile__progress-bar"}
                        percentage={percentage}
                        value={currentPage}
                        maxValue={pages}
                    />
                )}
                {isFuture && (
                    <div className={`topics-tile__time`}>
                        {courseDefinition?.timeEstimation
                            ? timeFormat(courseDefinition.timeEstimation)
                            : ""}
                    </div>
                )}
                {vertical && (
                    <button onClick={goToCourse} class="button-primary">
                        {course?.timeCompleted
                            ? "Start again"
                            : course?.timeStarted
                            ? "Continue"
                            : "Start now"}
                    </button>
                )}
            </div>
        </div>
    );
};

export default TopicsTile;
