import React from "react";
import cx from "classnames";
import "./style.css";
import { ReactComponent as PlayPause } from "@images/play-pause.svg";

const PlayPauseButton = ({
    className,
    onClick,
    isPlaying,
    isPrimary = true,
}) => {
    return (
        <PlayPause
            onClick={onClick}
            className={cx("play-pause-button", className, {
                "is-playing": isPlaying,
                "is-primary": isPrimary,
            })}
        />
    );
};

export default PlayPauseButton;
