import React from "react";
import { useFirestoreConnect } from "react-redux-firebase";
import { useSelector } from "react-redux";
import Badge from "@components/Badge";
import PageWrapper from "@components/PageWrapper";
import cx from "classnames";

import "./style.css";

const LevelInfoModal = ({ onClose }) => {
    useFirestoreConnect([
        {
            collection: "badgeGroups/mainBadges/badgeItems",
            storeAs: "mainBadges",
            queryParams: ["orderByChild=level"],
        },
    ]);

    const modalData = useSelector((state) => state.app.modal.content?.data);

    const allBadges = useSelector((state) => state.firestore.data?.mainBadges);

    const lvl = parseInt(modalData.level, 10);

    return (
        <div className={cx("message")}>
            <PageWrapper>
                <h2>Next Level</h2>

                <div className={cx(`level-info-modal`)}>
                    <p className={cx(`level-info-modal__text`)}>
                        To achieve the{" "}
                        <strong>{allBadges?.[`main${lvl}`]?.name}</strong>{" "}
                        level, you need to{" "}
                        {allBadges?.[`main${lvl}`]?.description}.
                    </p>
                    <div className={cx("level-info-modal__badge-wrapper")}>
                        <Badge level={lvl} />
                    </div>
                    <button className="button-primary" onClick={onClose}>
                        Got it
                    </button>
                </div>
            </PageWrapper>
        </div>
    );
};

export default LevelInfoModal;
