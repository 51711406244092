import React from "react";
import { useFirestoreConnect } from "react-redux-firebase";
import { useSelector } from "react-redux";
import Badge from "@components/Badge";
import PageWrapper from "@components/PageWrapper";
import cx from "classnames";
import { ReactComponent as CloseButton } from "@images/close.svg";
import { useTranslation } from "react-i18next";

import "./style.css";

const LevelUpModal = ({ onClose }) => {
    useFirestoreConnect([
        {
            collection: "badgeGroups/mainBadges/badgeItems",
            storeAs: "mainBadges",
            queryParams: ["orderByChild=level"],
        },
    ]);

    const modalData = useSelector((state) => state.app.modal.content?.data);

    const allBadges = useSelector((state) => state.firestore.data?.mainBadges);

    const lvl = parseInt(modalData.level, 10);

    const { t, i18n } = useTranslation();
    const language = i18n.language;

    return (
        <div className={cx("message", "message--badge")}>
            <PageWrapper>
                <CloseButton
                    className="level-up-modal__close"
                    onClick={onClose}
                />

                <div className={cx(`level-up-modal`)}>
                    <p className={cx(`level-up-modal__text`)}>
                        {allBadges?.[`main${lvl}`]?.descriptionPast[language]}{" "}
                    </p>
                    <div className={cx("level-up-modal__badge-wrapper")}>
                        <Badge level={lvl} enabled />
                    </div>
                    <div className={cx("level-up-modal__badge-name")}>
                        {allBadges?.[`main${lvl}`]?.name}
                    </div>
                    <button className="button-primary" onClick={onClose}>
                        {t('common.gotIt')}
                    </button>
                </div>
            </PageWrapper>
        </div>
    );
};

export default LevelUpModal;
