import { createSlice } from "@reduxjs/toolkit";

import firebase from "firebase";

import userCoursesSlice from "./userCourses";

export const initUserCourses = async (dispatch, userId) => {

  firebase.firestore()
    .collection("users")
    .doc(userId)
    .collection('userCourses')
    .onSnapshot(querySnapshot => {
      if (querySnapshot.empty) {
        dispatch(userCoursesSlice.actions.setCourses({}));
      } else {
        let userCourses = {};
        querySnapshot.forEach(doc => {
          const document = doc.data();
          const courseName = document.name;
        
          userCourses[courseName] = {
            id: doc.id,
            timeStarted: document.timeStarted,
            timeCompleted: document.timeCompleted,
            results: {}
          }

          if (document.results) {
            for (let [key, value] of Object.entries(document.results)) {
              if (key !== 'data') {
                userCourses[courseName].results[key] = value;
              }
            }
          }
        });
        dispatch(userCoursesSlice.actions.setCourses(userCourses));
      }
    })
};

export default createSlice({
  name: "userCourses",
  initialState: {
    courses: {}
  },
  reducers: {
    setCourses: (state, action) => {
      state.courses = action.payload;
    }
  }
});
