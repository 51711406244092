import { createSlice } from "@reduxjs/toolkit";

import firebase from "firebase";

import AppSlice from "./app";

export const init = async (dispatch, userId) => {
    const accountRef = firebase.firestore().collection("users").doc(userId);

    const mainId = (await accountRef.get()).data().mainCourse;

    const mainCourse = await firebase
        .firestore()
        .collection("courses")
        .doc(mainId)
        .get();

    dispatch(AppSlice.actions.setMainCourse({...mainCourse.data(), id: mainCourse.id}));

    const query = accountRef
        .collection(`userCourses/${mainId}/attempts/`)
        .orderBy("timeCompleted", "desc");

    query.onSnapshot(
        (querySnapshot) => {
            if (querySnapshot.empty) {
                dispatch(AppSlice.actions.setisStylePending(true));
                dispatch(AppSlice.actions.setLastCompletedCStyle(null));
                dispatch(AppSlice.actions.setCompletedCStyleCount(0));
            } else {
                dispatch(AppSlice.actions.setisStylePending(false));
                dispatch(
                    AppSlice.actions.setLastCompletedCStyle(
                        querySnapshot.docs[0].data()
                    )
                );
                dispatch(
                    AppSlice.actions.setCompletedCStyleCount(
                        querySnapshot.docs.length
                    )
                );
            }
        },
        () => {
            dispatch(AppSlice.actions.setisStylePending(true));
            dispatch(AppSlice.actions.setLastCompletedCStyle(null));
            dispatch(AppSlice.actions.setCompletedCStyleCount(0));
        }
    );

    const assertivenessRef = await firebase.firestore().collection('courses').where('name.en', '==','Building assertiveness').get();
    const pwcRef = await firebase.firestore().collection('courses').where('name.en', '==','Presenting in a second language').get();
    const assertivenessId = assertivenessRef.docs[0].id;
    const pwcId = pwcRef.docs[0].id
    
    const assertivenessQuery = accountRef
        .collection(`userCourses/${assertivenessId}/attempts/`)
        .orderBy("timeCompleted", "desc");

    const pwcQuery = accountRef
        .collection(`userCourses/${pwcId}/attempts/`)
        .orderBy("timeCompleted", "desc");
    
    assertivenessQuery.onSnapshot((querySnapshot) => {
        if (querySnapshot.empty) {
            dispatch(AppSlice.actions.setLastCompletedAssertiveness(null))
        } else {
            dispatch(AppSlice.actions.setLastCompletedAssertiveness(
                querySnapshot.docs[0].data().results.percents
            ));
        }
    })

    pwcQuery.onSnapshot((querySnapshot) => {
        if (querySnapshot.empty) {
            dispatch(AppSlice.actions.setLastCompletedPwc(null))
        } else {
            dispatch(AppSlice.actions.setLastCompletedPwc(
                querySnapshot.docs[0].data().results.values
            ));
        }
    })
    
};

export default createSlice({
    name: "app",
    initialState: {
        header: { visible: true, showHamburger: true },
        footer: true,
        isStylePending: null,
        lastCompletedCStyle: null,
        lastCompletedAssertiveness: null,
        lastCompletedPWC: null,
        mainCourse: null,
        modal: {},
        menuOpen: false,
        showWelcome: false,
        player: {
            id: null,
            isPlaying: false,
            isVisible: true,
        },
        showCStyleCertificate: false,
        cstyleCertificateViewed: false,
        fbRemoteConfig: { privacyPolicy: null, termsAndConditions: null },
        cStyleText: [],
        videoPlayer: {
            source: '',
            isPlaying: ''
        },
        shareFeature: false,
        skipIntroModal: false,
        allowMultiLingual: false
    },
    reducers: {
        setHeader: (state, action) => {
            state.header = action.payload;
        },
        setFooter: (state, action) => {
            state.footer = action.payload;
        },
        setisStylePending: (state, action) => {
            state.isStylePending = action.payload;
        },
        setLastCompletedCStyle: (state, action) => {
            state.lastCompletedCStyle = action.payload;
        },
        setLastCompletedAssertiveness: (state, action) => {
            state.lastCompletedAssertiveness = action.payload;
        },
        setLastCompletedPwc: (state, action) => {
            state.lastCompletedPWC = action.payload;
        },
        setCompletedCStyleCount: (state, action) => {
            state.completedCStyleCount = action.payload;
        },
        setMainCourse: (state, action) => {
            state.mainCourse = action.payload;
        },
        setModal: (state, action) => {
            state.modal = action.payload;
        },
        setMenu: (state, action) => {
            state.menuOpen = action.payload;
        },
        setWelcome: (state, action) => {
            state.showWelcome = action.payload;
        },
        setPlayer: (state, action) => {
            state.player = { ...state.player, ...action.payload };
            if (typeof action.payload.isPlaying === "boolean") {
                state.player.isPlaying = action.payload.isPlaying;
            } else {
                // toggle play/pause by default
                state.player.isPlaying = !state.player.isPlaying;
            }
        },
        setShowCStyleCertificate: (state, action) => {
            state.showCStyleCertificate = action.payload;
        },
        setCStyleCertificateViewed: (state, action) => {
            state.cstyleCertificateViewed = action.payload;
        },
        fbRemoteConfig: (state, action) => {
            state.fbRemoteConfig = action.payload;
        },
        setCStyleText: (state, action) => {
            if(action.payload) {
                state.cStyleText.push(action.payload);
            }
        },
        setVideoPlayer: (state, action) => {
            state.videoPlayer = { ...state.videoPlayer, ...action.payload };
        },
        setShareFeature: (state, action) => {
            state.shareFeature = action.payload;
        },
        setSkipIntroModal: (state, action) => {
            state.skipIntroModal = action.payload === 'true';
        },
        setAllowMultiLingual: (state, action) => {
            state.allowMultiLingual = action.payload === 'true';
        }
    },
});
