import React from "react";
import "./style.css";
import { ReactComponent as CertificateIcon } from "@images/certificate-icon.svg";
import { format } from "date-fns";

const CertificatesTile = (props) => {
    const { onClick, timeReceived, courseName } = props;

    const timeReceivedFormatted = format(new Date(timeReceived), "dd/MM/yyyy");

    return (
        <>
            <div className="certificates-tile" onClick={onClick}>
                <div className="certificates-tile__item">
                    <div className="certificates-tile__image">
                        <CertificateIcon />
                    </div>
                    <div className="certificates-tile__item__title">
                        <h4>{courseName}</h4>
                        <h5>{timeReceivedFormatted}</h5>
                    </div>
                </div>
            </div>
        </>
    );
};

export default CertificatesTile;
