import React, { useEffect } from "react";
import "./style.css";
import { isEmpty } from "react-redux-firebase";
import PageWrapper from "@components/PageWrapper";
import AppSlice from "@store/app";
import { useSelector, useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";

const GenericContent = (props) => {
    const { content } = props;
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const auth = useSelector((state) => state?.firebase?.auth);

    useEffect(() => {
        if (isEmpty(auth)) {
            dispatch(
                AppSlice.actions.setHeader({
                    visible: true,
                    showHamburger: false,
                })
            );
        } else {
            dispatch(
                AppSlice.actions.setHeader({
                    visible: true,
                    showHamburger: true,
                })
            );
        }
    }, [auth, dispatch]);

    const helpBody = (
        <>
            <h2>{t("help.pageHeader")}</h2>
            <p>
                {t("help.mainHeader.text")}
                <a href="mailto:my-support@rippl.ai">{t("help.mainHeader.link")}</a>
            </p>
        </>
    );

    const CONTENT_TYPES = {
        help: helpBody,
    };

    return (
        <div className="generic-content">
            <PageWrapper>{CONTENT_TYPES[content.type]}</PageWrapper>
        </div>
    );
};

export default GenericContent;
