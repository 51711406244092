import React from 'react';
import { useTranslation } from 'react-i18next';

//redux
import { useDispatch } from "react-redux";
import AppSlice from '@store/app';

//pictures and icons
import { ReactComponent as Background } from "@images/background.svg";
import { FaAngleRight } from "react-icons/fa";

//styles
import cx from "classnames";
import './Graph.css';

const Graph = ({ left, top, leftX, rightX, topY, bottomY, showCStyleLabels }) => {
  const dispatch = useDispatch();
  const { t, i18n } = useTranslation();

  const connectingVideo = 'https://player.vimeo.com/video/461431074';
  const influencingVideo = 'https://player.vimeo.com/video/461434024';
  const examiningVideo = 'https://player.vimeo.com/video/461433857';
  const deliveringVideo = 'https://player.vimeo.com/video/464747408';

  const playVideo = (src, name = '', playedFrom = '') => {
    dispatch(AppSlice.actions.setVideoPlayer({
        source: src,
        isPlaying: true,
        name: name,
        playedFrom: playedFrom || 'map'
    }))
  }
  

  return (
    <div className="graph-container">
      <div className="c-style-graph">
        <div className="c-style-graph-wrapper">
            <span className="labels labels__introvert">
                {leftX}
            </span>
            <span className={`labels labels__extrovert`}>
                {rightX}
            </span>
            <span className="labels labels__people">
                {topY}
            </span>
            <span className="labels labels__facts">
                {bottomY}
            </span>

            <div className={`connecting ${showCStyleLabels ? 'show': 'hide'} `}>
                <div className="label-container">
                    <Background
                        className={cx(
                            "labels-mutual",
                            `bg-connecting`
                        )}
                    />
                    <div className="labels-text" onClick={ () => playVideo(connectingVideo, 'Connecting') }>
                        <h3>{t('graph.connecting')}</h3><span><FaAngleRight /></span>
                    </div>
                </div>
            </div>

            <div className={`influencing ${showCStyleLabels ? 'show': 'hide'} `}>
                <div className="label-container">
                    <Background
                        className={cx(
                            "labels-mutual",
                            `bg-influencing`
                        )}
                    />
                    <div className="labels-text" onClick={ () => playVideo(influencingVideo, 'Influencing') }>
                        <h3>{t('graph.influencing')}</h3><span><FaAngleRight /></span>
                    </div>
                </div>
            </div>

            <div className={`examining ${showCStyleLabels ? 'show': 'hide'} `}>
                <div className="label-container">
                    <Background
                        className={cx(
                            "labels-mutual",
                            `bg-examining`
                        )}
                    />
                    <div className="labels-text" onClick={ () => playVideo(examiningVideo, 'Examining') }>
                        <h3>{t('graph.examining')}</h3><span><FaAngleRight /></span>
                    </div>
                </div>
            </div>

            <div className={`delivering ${showCStyleLabels ? 'show': 'hide'} `}>
                <div className="label-container">
                    <Background
                        className={cx(
                            "labels-mutual",
                            `bg-delivering`
                        )}
                    />
                    <div className="labels-text" onClick={ () => playVideo(deliveringVideo, 'Delivering') }>
                        <h3>{t('graph.delivering')}</h3><span><FaAngleRight /></span>
                    </div>
                </div>
            </div>
          
            <div className="c-style-graph-wrapper__dot-boundary">
                <div className="dot-container"
                    style={{ left, top }}
                >
                    <div
                        className="dot"
                    ></div>
                    <div className="dot-here-text">{t('graph.youAreHere')}</div>
                </div>
            </div>
        </div>
    </div>
  </div>
  )
}

export default Graph;