import React, { useEffect, useCallback , useState } from 'react'
import Vimeo from "@u-wave/react-vimeo";
import AppSlice from "@store/app";
import { useSelector, useDispatch } from 'react-redux';
import { IoCloseCircleOutline } from "react-icons/io5";
import { analyticsLogEvent } from '@components/Analytics';

const CustomVideoPlayer = () => {
  const video = useSelector(state => state.app.videoPlayer);
  const dispatch = useDispatch();
  
  useEffect(() => {
    if (video.name) {
      analyticsLogEvent('play_video', { name: video.name, played_from: video.playedFrom })
    }
  }, [video.name])

  const stopVideo = () => {
    dispatch(AppSlice.actions.setVideoPlayer({
      source: '',
      isPlaying: false,
      name: '',
      playedFrom: ''
    }))
    analyticsLogEvent('stop_video', { name: video.name })
  }

  return (
    <div 
      className={`${video && video.isPlaying ? 'labels-video-playing' : 'labels-video-player'}`}
    >
      { video && video.source   
          ?<div className="video-modal">
              <div className="video-modal__video-wrapper">
                <canvas width="9" height="16" />
                <div className="video-modal__video-wrapper--close">
                    <IoCloseCircleOutline onClick={() => stopVideo() } />
                </div>
                <Vimeo
                  video={video.source}
                  autoplay
                  muted={false}
                  width="100%"
                  height="100%"
                  className="video-modal__vimeo"
                />
              </div>
          </div>
          : null
      }
    </div>
  )
}

export default CustomVideoPlayer;