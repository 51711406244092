import { useLocation } from "react-router-dom";
import { useEffect } from "react";
import firebase from "firebase/app";

const FirebaseAnalytics = () => {
    let location = useLocation();
    useEffect(() => {
        const analytics = firebase && firebase.analytics;
        if (typeof analytics === "function") {
            const page_path = location.pathname + location.search;
            analytics().setCurrentScreen(page_path);
            analytics().logEvent("page_view", { page_path });
        }
    }, [location]);
    return null;
};

export default FirebaseAnalytics;

export const analyticsLogEvent = (event, eventParams) => {
    const analytics = firebase && firebase.analytics;
    if(typeof analytics === 'function') {
        console.log('loging event: ' + event);
        console.log('eventParams: ', eventParams);
        
        analytics().logEvent(event, eventParams)
    }

    return null;
}