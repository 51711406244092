import React from 'react';
import { useDispatch } from 'react-redux';
import AppSlice from '@store/app';
import { IoPlay } from 'react-icons/io5'
import './PersonalisedVideo.css';

const PersonalisedVideo = ({ bgClass, src, name, playedFrom }) => {
  const dispatch = useDispatch();
  const playVideo = (src) => {
    dispatch(AppSlice.actions.setVideoPlayer({
        source: src,
        isPlaying: true,
        name: name,
        playedFrom: playedFrom
    }))
  }

  return (
    <div className={`personalised-video ${bgClass}-preview`}
      onClick={() => playVideo(src)}
    >
      <IoPlay />
    </div>
  )
}

export default PersonalisedVideo
