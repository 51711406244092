import React from 'react';
import './PageSpinner.css';

const PageSpinner = () => {
  return (
    <div className="page-spinner-container">
      <div className="spinner-container">
        <div className="spinner"></div>
        <h1>Loading...</h1>
      </div>
    </div>
  )
}

export default PageSpinner
