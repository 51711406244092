import { useSelector } from "react-redux";
import { useFirestoreConnect, isLoaded } from "react-redux-firebase";
import getPodcastStats from "@utils/getPodcastStatus";

const useGroupedPodcastsList = (userId) => {
    useFirestoreConnect([
        {
            collection: "users",
            doc: userId,
            subcollections: [{ collection: "userPodcasts" }],
            storeAs: "myPodcasts",
        },
        { collection: "podcasts" },
    ]);

    const allPodcasts = useSelector((state) => state.firestore.data?.podcasts);

    const podcasts = useSelector((state) => {
        const userPodcastsData = state.firestore.ordered.myPodcasts || [];

        return userPodcastsData.map((p) => {
            let { ...rest } = p;

            return { ...rest };
        });
    });

    if (isLoaded(allPodcasts))
        return podcasts.reduce(
            (acc, item) => {
                const stats = getPodcastStats(item);
                item.image = allPodcasts?.[item.id]?.image;
                if (stats.isInProgress) {
                    acc.inProgress.push({ ...item, stats });
                } else if (stats.isFuture) {
                    acc.future.push({ ...item, stats });
                } else if (stats.isCompleted) {
                    acc.completed.push({ ...item, stats });
                }

                return acc;
            },
            {
                inProgress: [],
                future: [],
                completed: [],
            }
        );
};

export default useGroupedPodcastsList;
