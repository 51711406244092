import React, { useMemo, useEffect, useContext, useState } from 'react';
import PageWrapper from '@components/PageWrapper';
import { IoChevronDownOutline } from "react-icons/io5";
import { IoChevronUpCircleOutline } from "react-icons/io5";
import { IoChevronDownCircleOutline } from "react-icons/io5";
import $ from 'jquery'
import { v4 as uuid } from 'uuid';
import { useSelector, useDispatch } from 'react-redux';
import SiSlice from '@store/si';
import AppSlice from '@store/app';
import { QuestionContext } from "@components/SurveyComponent/customWidgets";
import './SI_FirstPage.css';

const SI_FirstPage = React.memo((props) => {
  const q = useContext(QuestionContext);
  q.isRequired = true;

  const dispatch = useDispatch();

  const [sorted, setSorted] = useState(false);
  const questions = useSelector(state => state.si.sections);
  const sectionStatus = useSelector(state => state.si.sectionStatus); 
  const cModal = useSelector(state => state.app.modal)
  const phase = useMemo(() => {
    if (!cModal.showed) {
      return 'first';
    } else {
      return 'second';
    }
  }, [cModal.showed])

  const doNumbering = (questions) => {
    const questionCopy = JSON.parse(JSON.stringify(questions));

    let numbering = 1;
    questionCopy.beginning.questions.forEach((question,index) => {
      questionCopy.beginning.questions[index].spanVal = numbering;
      numbering++;
    })
    questionCopy.middle.questions.forEach((question,index) => {
      questionCopy.middle.questions[index].spanVal = numbering;
      numbering++;
    })
    questionCopy.end.questions.forEach((question,index) => {
      questionCopy.end.questions[index].spanVal = numbering;
      numbering++;
    })

    return questionCopy;
  }

  const changeSection = (question, section) => {
    const sectionStatusCopy = JSON.parse(JSON.stringify(sectionStatus))
    let questionsCopy = JSON.parse(JSON.stringify(questions));

    if (sectionStatusCopy[section].canAdd) {
      let removedFromPrevSection = {
        status: false,
        section: ''
      };
      let foundQuestion = questionsCopy.all.questions.find(el => el.id === question.id);
      if (foundQuestion) {
        questionsCopy.all.questions = questionsCopy.all.questions.filter(q => q.id !== question.id)
        removedFromPrevSection.status = true;
        removedFromPrevSection.section = 'all';
      }
      
      if (!foundQuestion) {
        foundQuestion = questionsCopy.beginning.questions.find(el => el.id === question.id);
        if (foundQuestion && sectionStatusCopy['beginning'].canRemove) {
          questionsCopy.beginning.questions = questionsCopy.beginning.questions.filter(q => q.id !== question.id);
          removedFromPrevSection.status = true;
          removedFromPrevSection.section = 'beginning';
        }
      }
  
      if (!foundQuestion) {
        foundQuestion = questionsCopy.middle.questions.find(el => el.id === question.id);
        if (foundQuestion && sectionStatusCopy['middle'].canRemove) {
          questionsCopy.middle.questions = questionsCopy.middle.questions.filter(q => q.id !== question.id)
          removedFromPrevSection.status = true;
          removedFromPrevSection.section = 'middle';
        }
      }

      if (!foundQuestion) {
        foundQuestion = questionsCopy.end.questions.find(el => el.id === question.id);
        if (foundQuestion && sectionStatusCopy['end'].canRemove) {
          questionsCopy.end.questions = questionsCopy.end.questions.filter(q => q.id !== question.id)
          removedFromPrevSection.status = true;
          removedFromPrevSection.section = 'end';
        }
      }
      
      let addedToNewSection = {
        status: false,
        section: ''
      }
      if (foundQuestion && removedFromPrevSection.status) {
        switch (section) {
          case "beginning":
            questionsCopy.beginning.questions.push(question);
            addedToNewSection.status = true;
            addedToNewSection.section = 'beginning'
            break;
          case "middle":
            questionsCopy.middle.questions.push(question);
            addedToNewSection.status = true;
            addedToNewSection.section = 'middle'
            break;
          case "end":
            questionsCopy.end.questions.push(question)
            addedToNewSection.status = true;
            addedToNewSection.section = 'end'
            break;
          default:
            console.log("Invalid operation");
        }
      }
      updateSectionStatus(removedFromPrevSection, addedToNewSection)
    }

    questionsCopy = doNumbering(questionsCopy);
    dispatch(SiSlice.actions.setSections(questionsCopy));
  }

  const updateSectionStatus = (removedFromPrevSection, addedToNewSection) => {
  

    const questionsCopy = JSON.parse(JSON.stringify(questions));

    const newObject = JSON.parse(JSON.stringify(sectionStatus));

    if (removedFromPrevSection.status) {
      //if removed from previous section succeed 

      //check if the new number of questions in that section meets the criteria to allow any more removings
      if (questionsCopy[removedFromPrevSection.section].min < questionsCopy[removedFromPrevSection.section].questions.length - 1) {
        newObject[removedFromPrevSection.section].canRemove = true;
      } else {
        newObject[removedFromPrevSection.section].canRemove = false;
      } 

      //check if the new number of questions in that section meets the criteria to allow any more addings
      if (questionsCopy[removedFromPrevSection.section].questions.length - 1  < questionsCopy[removedFromPrevSection.section].max) {
        newObject[removedFromPrevSection.section].canAdd = true;
      } else {
        newObject[removedFromPrevSection.section].canAdd = false;
      }
    } 
    
    if (addedToNewSection.status) {
      //if added to new section succeed 

      //check if the new number of questions in that section meets the criteria to allow any more removings
      if (questionsCopy[addedToNewSection.section].min < questionsCopy[addedToNewSection.section].questions.length +1) {
        newObject[addedToNewSection.section].canRemove = true;
      } else {
        newObject[addedToNewSection.section].canRemove = false;
      } 

      //check if the new number of questions in that section meets the criteria to allow any more addings
      if (questionsCopy[addedToNewSection.section].questions.length + 1 < questionsCopy[addedToNewSection.section].max) {
        newObject[addedToNewSection.section].canAdd = true;
      } else {
        newObject[addedToNewSection.section].canAdd = false;
      }
    }

    dispatch(SiSlice.actions.setSectionStatus(newObject));
  }

  const handleArrow = (section, questionId, direction) => {
  
    //make a deep copy of the questions object
    let newQuestions = JSON.parse(JSON.stringify(questions));

    //find index of targeted question
    const indexOfQuestion = newQuestions[section].questions.findIndex(q => q.id === questionId);

    if (indexOfQuestion !== -1) {
      if ((direction === 1 && indexOfQuestion < newQuestions[section].questions.length - 1) || (direction === -1 && indexOfQuestion > 0 )) {
        let auxilarObject = newQuestions[section].questions[indexOfQuestion]; // point to 

        //move down the question above
        newQuestions[section].questions[indexOfQuestion] = newQuestions[section].questions[indexOfQuestion + direction];

        //move target question up
        newQuestions[section].questions[indexOfQuestion + direction] = auxilarObject;
      }
  
    } else {
      //something to do if the question was not found
      console.log(`Question with id '${questionId}' was not found in section '${section}'`);
    }
    
    newQuestions = doNumbering(newQuestions);
    dispatch(SiSlice.actions.setSections(newQuestions));
     
  }

  const enableNextButton = () => {
    var nextButton = document.querySelector(".custom-sv-next");
    nextButton.disabled = false;
    nextButton.classList.remove("custom-sv-next--disabled");
  }

  //this use effect is executed everytime section stats changes
  //but the logic inside it is executed only when all the questions were placed to different sections
  //its job is to set question property isAnswerd to true and enable 'next' button
  useEffect(() => {
    if (!sectionStatus.all.canRemove) {
      q.isAnswered = true;
      setSorted(true);
      enableNextButton();
    }
  }, [sectionStatus, cModal, q])

  //this useEffect runs everytime section status changes. 
  //when 'all' section can no longer remove items then the modal is being shown
  useEffect(() => {
    if (!sectionStatus.all.canRemove && !cModal.showed) {
      dispatch(AppSlice.actions.setModal({
        visible: true,
        content: {
          type: 'SI_FirstPhase_Done'
        },
        showed: true
      }));
    }
  }, [sectionStatus, cModal, dispatch])

  //this use effect happens when the component mounts
  //it is exectued only once and its job is to populate redux state with data from survey js
  //it also has to over write survey component css 
  useEffect(() => {
    if (questions.all.questions.length === 0 && questions.beginning.questions.length === 0 && questions.middle.questions.length === 0 && questions.end.questions.length === 0 ) {
      dispatch(SiSlice.actions.initSections(props.questions));
    }
    $('.survey-component__survey .sv_main').addClass('overWriteSurvey');
    $('.survey-component__survey .sv_body').css('line-height', 'normal');

    return () => {
      $('.survey-component__survey .sv_main').removeClass('overWriteSurvey');
    }
  }, [questions.all.questions.length, 
      questions.beginning.questions.length, 
      questions.middle.questions.length, 
      questions.end.questions.length, 
      props.questions, 
      dispatch])

  const handleEmptySection = () => {
    if (questions.beginning.questions.length < 1) {
      return {end: 'disable_end', middle: 'disable_middle'}
    }
    if (questions.end.questions.length < 1) {
      return {beginning: 'disable_beginning', middle: 'disable_middle'}
    }
  }

  return (
    <div className="firstPage-container">
      <PageWrapper>

      <div className="instructions-container">
          <div className="text-container si-page-width">
            <h1>Get your story straight</h1>
            {
              phase === 'first'
                ? <p>
                    Structure is everything, so let’s get the 10 key messages from the GSEM organised into a story that’s easy to tell.  
                    <br /><br />
                    Tap the ‘Beginning’, ‘Middle’ or ‘End’ button for each message, to automatically place it in that section. 
                  </p>
                : <p>
                    For a deeper understanding of each key message, ‘Show’ or ‘Hide’ the speaker notes and use them to help you reorder your messages. 
                    <br /><br />
                    When you are happy with your story click ‘Next’ to continue.
                  </p>
            }
          </div>
        </div>

        <div className="beginning-container si-page-width">
          <h1>BEGINNING <span>(max. 2)</span></h1>
          <div className="cards-container blue">
            {
              questions.beginning && questions.beginning.questions.map((question,index) => {
                const keyId = uuid();
                return (
                  <CardComponent 
                    key={keyId}
                    question={question}
                    section='beginning'
                    changeSection={changeSection}
                    handleArrow={handleArrow}
                    sectionStatus={sectionStatus}
                    upArrow = {index > 0}
                    downArrow = {index < questions.beginning.questions.length - 1}
                    sorted={sorted}
                  />
                )
              })
            }
          </div>
        </div>

        <div className="middle-container si-page-width">
          <h1>MIDDLE</h1>
          <div className="cards-container yellow">
            { 
              questions.middle && questions.middle.questions.map((question,index) => {
                const keyId = uuid();
                return (
                  <CardComponent 
                    key={keyId}
                    question={question}
                    section='middle'
                    changeSection={changeSection}
                    handleArrow={handleArrow}
                    sectionStatus={sectionStatus}
                    upArrow = {index > 0}
                    downArrow = {index < questions.middle.questions.length - 1 }
                    sorted={sorted}
                  />
                )
              })
            }
          </div>
        </div>

        <div className="end-container si-page-width">
          <h1>END <span>(max. 2)</span></h1>
          <div className="cards-container orange">
            {
              questions.end && questions.end.questions.map((question,index) => {
                const keyId = uuid();
                return(
                  <CardComponent 
                    key={keyId}
                    question={question}
                    section='end'
                    changeSection={changeSection}
                    handleArrow={handleArrow}
                    sectionStatus={sectionStatus}
                    upArrow = {index > 0}
                    downArrow = {index < questions.end.questions.length - 1}
                    sorted={sorted}
                  />
                )
              })
            }
          </div>
        </div>

        <div className="questions-container si-page-width">
          {
            questions.all && questions.all.questions.map((question, index) => {
              const keyId = uuid();
              return(
                <CardComponent 
                  key={keyId}
                  question={question} 
                  changeSection={changeSection}
                  handleArrow={handleArrow}
                  sectionStatus={sectionStatus}
                  handleEmptySection={questions.all.questions.length === 1 ? handleEmptySection() : {} }
                />
              )
            })
          }
        </div>
        <button onClick={()=> {q.isAnswered = true}}>
          sss
        </button>
      </PageWrapper>
    </div>
  )
})

export default SI_FirstPage


//create card component inside SI_FirstPage component file. If we need to 
//reuse this component later move it into its own file

const CardComponent = ({ question, upArrow, downArrow, section, changeSection, handleArrow, sectionStatus, handleEmptySection, sorted }) => {
  const { id, spanVal, text, btn1, btn2, btn3, more } = question;
  let expanded = false;
  
  const expandCard = (id) => {
    //rotate chevron
    $(`#chevron${id}`).addClass('rotate-up');

    //add display class to "more text"
    $(`#text-card-${id}`).slideDown(500);

    //change 'show all' text to 'hide all'
    $(`#showOrHide-${id}`).text('Hide note');

    expanded = true;
  }

  const colapseCard = (id) => {
    $(`#chevron${id}`).removeClass('rotate-up');
    $(`#text-card-${id}`).slideUp(300);
    $(`#showOrHide-${id}`).text('Show note');
    expanded = false;
  }

  return (
    <div className="card-component-container">
      <div className="question-text-container">
        <span className="questionRanking">{spanVal || '-'}</span><h1>{text}</h1>
      </div>

      <div className={sorted ? "more-text-container" : "d-none"}>
        <div 
          className="text-controls"
          onClick={() => {
            if (!expanded) {
              expandCard(id);
            } else {
              colapseCard(id);
            }
          }}
        >
            <span 
              id={`showOrHide-${id}`}
              className="showOrHide"
            >
              Show note
            </span>
            <span 
              className="chevron-default"
              id={`chevron${id}`}
            >
              <IoChevronDownOutline />
            </span>
        </div>

        <div id={`text-card-${id}`} className="d-none text-card">
          <ul className="ul-container">
            {
              more.map(text => {
                const keyId = uuid();
                return (
                  <li key={keyId}>
                    {text}
                  </li>
                )
              }) 
            }
          </ul>
        </div>
      </div>                
       
      <div className={`${sectionStatus[section] && !sectionStatus[section].canRemove ? 'd-none' : 'buttons-container'}`}>
        <button 
          className={`${!sectionStatus.beginning.canAdd  || handleEmptySection?.beginning === 'disable_beginning' ? 'disabledBtn' :'null'} blueBtn`}
          onClick={() => {
            if (!sectionStatus.beginning.canAdd || handleEmptySection?.beginning === 'disable_beginning') {
              return null;
            } else {
              changeSection(question, "beginning");
            }
          }}
        >
          {btn1}
        </button>

        <button 
          className={`${!sectionStatus.middle.canAdd || handleEmptySection?.middle === 'disable_middle' ? 'disabledBtn' :'null'} yellowBtn`}
          onClick={() => {
            if (!sectionStatus.middle.canAdd || handleEmptySection?.middle === 'disable_middle') {
              return null;
            } else {
              changeSection(question, "middle");
            }
          }}
        >
          {btn2}
        </button>
    
        <button 
          className={`${!sectionStatus.end.canAdd || handleEmptySection?.end === 'disable_end' ? 'disabledBtn' :'null'} orangeBtn`}
          onClick={() => {
            if (!sectionStatus.end.canAdd || handleEmptySection?.end === 'disable_end') {
              return null;
            } else {
              changeSection(question, "end");
            }
          }}
        >
          {btn3}
        </button>

        {
          upArrow && 
          <button 
            className={`${upArrow && sorted? 'upArrow' : 'd-none'} arrow`}
            onClick={() => { handleArrow(section, id, -1) }}
          >
            <IoChevronUpCircleOutline />
          </button>
        }

        {
          downArrow &&
          <button 
            className={`${downArrow && sorted ? 'downArrow' : 'd-none'} arrow`}
            onClick={() => { handleArrow(section, id, 1) }}
          >
            <IoChevronDownCircleOutline />
          </button>
        }
        
      </div>
    </div>
  )
}