import React from "react";
import { IfResultInRange } from "@components/Results";
import "./style.css";

const ResultQuadrant = ({ noRange, range, children }) => {
    const renderChildren = () => {
        const elements = React.Children.toArray(children);

        if (noRange) {
            // remove mine, show general description
            return elements.filter(
                (f) =>
                    !f.props["data-in-range"] ||
                    f.props["data-in-range"] === "false"
            );
        } else {
            // remove general, show my own description
            return elements.filter(
                (f) =>
                    !f.props["data-in-range"] ||
                    f.props["data-in-range"] === "true"
            );
        }
    };

    return (
        <IfResultInRange range={range} noRange={noRange}>
            <div className="result_quadrant">
                <div className="quadrant-container">
                    {/* Display paragraphs  */}
                    <div className="subzone-paragraph">
                        {renderChildren().map((el, i) => {
                            if (el.type !== "iframe") {
                                return el;
                            }
                            return null;
                        })}
                    </div>
                </div>
            </div>
        </IfResultInRange>
    );
};

export default ResultQuadrant;
