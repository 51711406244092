import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "@components/App";
import reportWebVitals from "./reportWebVitals";
import "@styles/breakpoints.css";
import "@styles/normalize.css";
import "@styles/typography.css";
import "@styles/global.css";
import "@styles/common.css";
import "@styles/fonts.css";
import "./i18n";

ReactDOM.render(
    <React.StrictMode>
        <App />
    </React.StrictMode>,
    document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
