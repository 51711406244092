import React from "react";
import "./style.css";
import PageWrapper from "@components/PageWrapper";
import "../GenericContent/style.css";
import { useTranslation } from "react-i18next";

const About = () => {
    const { t } = useTranslation();

    return (
        <div className="generic-content generic-content--about-page">
            <PageWrapper>
                <h2>{t("about.pageHeader")}</h2>
                <p>
                    {t("about.mainHeader.firstParagraph")}
                </p>

                <p>
                    {t("about.mainHeader.secondParagraph")}
                </p>
                <h3>{t("about.coursesSection.title")}</h3>
                <p>
                    {t("about.coursesSection.text")}
                </p>
                <h4>{t("about.coursesSection.basics.title")}</h4>
                <ul>
                    <li>{t("about.coursesSection.basics.cStyle")}</li>
                    <li>{t("about.coursesSection.basics.assertiveness")}</li>
                    <li>{t("about.coursesSection.basics.flexingCStyle")}</li>
                </ul>
                <h4>{t("about.coursesSection.presenting.title")}</h4>
                <ul>
                    <li>{t("about.coursesSection.presenting.secondLanguage")}</li>
                    <li>{t("about.coursesSection.presenting.virtualMeetings")} </li>
                    <li>{t("about.coursesSection.presenting.dataStories")}</li>
                    <li>{t("about.coursesSection.presenting.presentationSkills")}</li>
                </ul>

                <h3>{t("about.ethosSection.title")}</h3>
                <p>{t("about.ethosSection.text")}</p>
                
                <h3>{t("about.expertsSection.title")}</h3>
                <p>{t("about.expertsSection.firstParagraph")}</p>
                <p>{t("about.expertsSection.secondParagraph")}</p>

                <p>{t("about.expertsSection.firstList.title")}</p>
                <ul>
                    <li>{t("about.expertsSection.firstList.item1")}</li>
                    <li>{t("about.expertsSection.firstList.item2")}</li>
                    <li>{t("about.expertsSection.firstList.item3")}</li>
                    <li>{t("about.expertsSection.firstList.item4")}</li>
                    <li>{t("about.expertsSection.firstList.item5")}</li>
                    <li>{t("about.expertsSection.firstList.item6")}</li>
                    <li>{t("about.expertsSection.firstList.item7")}</li>
                    <li>{t("about.expertsSection.firstList.item8")}</li>
                    <li>{t("about.expertsSection.firstList.item9")}</li>
                </ul>

                <p>{t("about.expertsSection.secondList.title")}</p>
                <ul>
                    <li>
                        {t("about.expertsSection.secondList.item1.text")}
                        <a href="https://visme.co/blog/the-4-communication-styles-quiz/">
                            {t("about.expertsSection.secondList.item1.link")}
                        </a>
                    </li>
                    <li>
                        {t("about.expertsSection.secondList.item2.text")}
                        <a href="https://financiallysimple.com/disc-assessment-offering-insights-how-to-build-a-compatible-staff/">
                            {t("about.expertsSection.secondList.item2.link")}
                        </a>{" "}
                        {t("about.expertsSection.secondList.item2.secondText")}
                    </li>
                    <li>
                        {t("about.expertsSection.secondList.item3")}
                    </li>
                    <li>
                        {t("about.expertsSection.secondList.item4")}
                    </li>
                    <li>
                        {t("about.expertsSection.secondList.item5")}
                    </li>
                    <li>
                        {t("about.expertsSection.secondList.item6")}
                    </li>
                    <li>
                        {t("about.expertsSection.secondList.item7")}
                    </li>
                    <li>
                        {t("about.expertsSection.secondList.item8")}
                    </li>
                    <li>
                        {t("about.expertsSection.secondList.item9")}
                    </li>
                    <li>
                        {t("about.expertsSection.secondList.item10.text")}
                        <a href="https://www.forbes.com/sites/markmurphy/2015/08/06/which-of-these-4-communication-styles-are-you">
                            {t("about.expertsSection.secondList.item10.link")}
                        </a>{" "}
                        {t("about.expertsSection.secondList.item10.secondText")}
                    </li>
                    <li>
                        {t("about.expertsSection.secondList.item11.text")}
                        <a href="http://www.clairenewton.co.za/">
                            {t("about.expertsSection.secondList.item11.link")}
                        </a>{" "}
                        {t("about.expertsSection.secondList.item11.secondText")}
                    </li>
                    <li>
                        {t("about.expertsSection.secondList.item12.text")}
                        <a href="https://openpsychometrics.org/tests/">
                            {t("about.expertsSection.secondList.item12.link")}
                        </a>
                    </li>
                    <li>
                        {t("about.expertsSection.secondList.item13")}
                    </li>
                </ul>
            </PageWrapper>
        </div>
    );
};

export default About;
