import firebase from "firebase";

// Get first attempt for every user course.
// We get the first attempt since it's the one valid for the certificate.
export const getFirstAttempt = async (userId) => {
    const userCoursesQuerySnap = await firebase
        .firestore()
        .collection("users")
        .doc(userId)
        .collection("userCourses")
        .get();

    const userPromises = userCoursesQuerySnap.docs.map(async (userCourseSnap) => {
        const attemptsQuerySnap = await userCourseSnap.ref
            .collection("attempts")
            .orderBy("timeCompleted", "asc")
            .limit(1)
            .get();

        return !attemptsQuerySnap.empty
            ? attemptsQuerySnap.docs[0].data()
            : null;
    });

    const attemptsArray = await Promise.all(userPromises);

	// Return array without null values.
    return attemptsArray.filter(i => i);
};

export const hasAttempt = async (userCoursesCollection, course) => {
    const attempts = await firebase
        .firestore()
        .collection(userCoursesCollection.trim())
        .doc(course.trim())
        .collection("attempts")
        .get();

    return !attempts.empty;
};
