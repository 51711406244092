import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
//hooks
import useCalculatePWCResults from '@hooks/useCalculatePWCResults';

//components
import PersonalisedVideo from '@components/PersonalisedVideo/PersonalisedVideo';
import CustomVideoPlayer from '@components/Video/CustomVideoPlayer';
import Instructions from '@components/Instructions'; 
import Graph from '@components/Graph/Graph';

//styles
import './PWCGraph.css';

const PWCGraph = ({question, survey, values}) => {
  const { t } = useTranslation();
  const calculateResults = useCalculatePWCResults();
  
  const [userResultsRange, setUserResultsRange] = useState({});
  const [staticP, setStaticP] = useState('');
  const [personalisedText, setPersonalisedText] = useState('');

  useEffect(() => {
    if (values) {
      setUserResultsRange(values);
    } else {
      const results = calculateResults(survey);
      setUserResultsRange({ ...results });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []) 

  const checkUserInRange = (range) => {
    let isInRange = false;
    if((range[0].x <= userResultsRange.x && userResultsRange.x <= range[1].x) && (range[0].y <= userResultsRange.y && userResultsRange.y <= range[1].y))
      isInRange = true;

    return isInRange;
  }

  useEffect(() => {
    let isMounted = true;
    if(isMounted) {
      setStaticP(question.staticText)
      if(userResultsRange) {
        for(let [, value] of Object.entries(question.personalisedText)) {
          if(checkUserInRange(value.range)) {
            setPersonalisedText(value.text);
          }
        }
      }
    }
    return () => { isMounted = false; }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userResultsRange])
  
  const x = (Math.abs(userResultsRange.x) * 0.9 + 0.1) * Math.sign(userResultsRange.x);
  const y = (Math.abs(userResultsRange.y) * 0.75 + 0.25) * Math.sign(userResultsRange.y);

  let left = `${((x + 1) / 2) * 100}`;
  let top = `${(1 - (y + 1) / 2) * 100}`;

  // avoid values near 0 not to be over the line
  if(30 <= left && left <= 50) left = 30;
  if(50 <= left && left <= 70) left = 70;
  if(35 <= top && top <= 50) top = 35;
  if(50 <= top && top <= 65) top = 65;
  
  left +='%';
  top += '%';

  return !userResultsRange || !top || !left || !personalisedText ? 'Loading...' : (
    <div className="pwc-results-container">
      <Instructions><p>{ staticP }</p>
      
      <Graph 
        leftX = {`${t('graph.clarity')} (-)`}
        rightX = {`${t('graph.clarity')} (+)`}
        topY = {`${t('graph.confidence')} (+)`}
        bottomY = {`${t('graph.confidence')} (-)`}
        showCStyleLabels = {false}
        left = {left}
        top = {top}
      />

      <div className="personalised-text">
        <h3>{personalisedText.header}</h3>
        <p>{personalisedText.paragraph}</p>
      </div>

      <p className="video-intro-text">{question.videoIntroText }</p>

      <div className="personalised-video-container">
        <PersonalisedVideo 
          src={question.videoSource}
          bgClass={`PWC`}
          name="PISL-Video"
          playedFrom="PISL_page"
        />   
      </div>

      </Instructions>

      {/* video player for PWC */}
      <CustomVideoPlayer />
      
    </div>
  )
}

export default PWCGraph
