import React, { useState, useEffect, useCallback } from "react";
import { useTranslation } from "react-i18next";
import { useFirestore } from "react-redux-firebase";
import { useFirestoreConnect } from "react-redux-firebase";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import useTakeCourse from "@hooks/useTakeCourse.js";
import PageWrapper from "@components/PageWrapper";
import AppSlice from "@store/app";
import classnames from "classnames";
import "./style.css";

const UpdateProfile = (props) => {
    const { modal } = props;
    const firestore = useFirestore();
    const history = useHistory();

    const dispatch = useDispatch();
    const takeCourse = useTakeCourse();
    const [disabled, setDisabled] = useState(false);

    const uid = useSelector((state) => state.firebase.auth.uid);
    const mainCourseId = useSelector(state => state.app.mainCourse?.id);
    const mainCourseName = useSelector(state => state.app.mainCourse?.name['en']);
    const skipIntroModal = useSelector(state => state.app?.skipIntroModal);
    const allowMultiLingual = useSelector(state => state.app?.allowMultiLingual);

    const { t, i18n } = useTranslation();

    useFirestoreConnect([
        {
            collection: "users",
            doc: uid,
        },
    ]);

    useFirestoreConnect({
        collection: "authorizedUsers",
        where: [["user", "==", `users/${uid}`]],
    });

    const prefillData = useSelector(
        (state) => state.firestore.ordered.authorizedUsers?.[0] ?? {}
    );

    const user = useSelector((state) => state.firestore.data?.users?.[uid] ?? {});

    const preferredLanguage = i18n.language || window.localStorage.getItem('rippl-pref-language');
    const [userData, setUserData] = useState({
        name: user?.name ?? prefillData.name ?? "",
        surname: user?.surname ?? prefillData.surname ?? "",
        role: user?.role ?? prefillData.role ?? "",
        language: preferredLanguage || "en"
    });

    // Update userData when user changes.
    useEffect(() => {
        const { namePrefill, surnameprefill, rolePrefill } = prefillData || {};
        const { name, surname, role } = user || {};
        setUserData({
            ...userData,
            name: name || namePrefill,
            surname: surname || surnameprefill,
            role: role || rolePrefill,
        });

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [user]);

    const [updated, setUpdated] = useState(false);

    const updateData = (event, action = null) => {
        if (action === 'change-language') {
            window.localStorage.setItem("rippl-pref-language", event.target.value);
            i18n.changeLanguage(event.target.value);
        }
        setUserData({
            ...userData,
            [event.target.name]: event.target.value,
        });
    };

    const submit = () => {
        if (!userData.name || !userData.surname) {
            alert('Please fill in all fields');
        } else {
            firestore.update({ collection: "users", doc: uid }, userData);
            if (skipIntroModal) {
                takeCourse(mainCourseId, 'go', mainCourseName);
            } else {
                setUpdated(true);
            }
        }
    };

    const close = useCallback(() => {
        dispatch(AppSlice.actions.setModal({ visible: false, content: null }));
    }, [dispatch]);

    const showModal = (type) => {
        dispatch(
            AppSlice.actions.setModal({
                visible: true,
                content: { type: type },
            })
        );
    };

    useEffect(() => {
        for (var key in userData) {
            if (userData[key] !== null && userData[key] !== "") {
                setDisabled(false);
            } else {
                setDisabled(true);
            }
        }
    }, [userData]);

    useEffect(() => {
        if (updated) {
            if (modal) {
                close();
            } else {
                history.push("/");
            }
        }
    }, [updated, history, modal, close]);

    const onBlur = (e) => {
        if (e.target.value === "" && prefillData[e.target.name]) {
            setUserData({
                ...userData,
                [e.target.name]: prefillData[e.target.name],
            });
        }
    };
    
    useEffect(() => {
        if (allowMultiLingual) {
            const languageSelect = document.getElementById('language-select');
            if (languageSelect.options && languageSelect.options[preferredLanguage]) {
                languageSelect.options[preferredLanguage].selected = true;
            }
        }
    }, [])

    return (
        <div
            className={classnames("update-profile", {
                "update-profile--modal": modal,
            })}
        >
            <PageWrapper className={classnames({ "full-height": !modal })}>
                <div>
                    {!modal && <p className="subtitle">{t("profile.signUp.button")}</p>}
                    <h1 className="big">{!modal && "Hello"}</h1>

                    <input
                        type="text"
                        id="name"
                        name="name"
                        placeholder={t("profile.name")}
                        onChange={updateData}
                        value={userData.name}
                        onBlur={onBlur}
                    />

                    <input
                        type="text"
                        id="surname"
                        name="surname"
                        placeholder={t("profile.surname")}
                        onChange={updateData}
                        value={userData.surname}
                        onBlur={onBlur}
                    />

                    <input
                        type="text"
                        id="role"
                        name="role"
                        placeholder={t("profile.role")}
                        onChange={updateData}
                        value={userData.role}
                        onBlur={onBlur}
                    />

                    {
                        allowMultiLingual && 
                        <select id="language-select" name="language" className="custom-select" onChange={(event) => updateData(event, 'change-language')}>
                            <option name="en" value="en">English</option>
                            <option name="es" value="es">Español</option>
                            <option name="pt" value="pt">Portuguese</option>
                        </select>
                    }
                </div>

                {modal ? (
                    <div className="update-profile__cta">
                        <button className="button-secondary" onClick={close}>
                            {t("profile.updateBtns.secondary")}
                        </button>
                        <button className="button-primary" onClick={submit}>
                            {t("profile.updateBtns.primary")}
                        </button>
                    </div>
                ) : (
                    <div className="update-profile__cta">
                        <p className="smaller">
                            {t("profile.signUp.title.text1")}
                            <span
                                onClick={() => {
                                    showModal("termsAndConditions");
                                }}
                            >
                                {t("profile.signUp.title.termsAndConditions")}
                            </span>
                            ,{" "}
                            <span
                                onClick={() => {
                                    showModal("privacyPolicy");
                                }}
                            >
                                {t("profile.signUp.title.privacyPolicy")}
                            </span>{" "}
                            {t("profile.signUp.title.text2")}
                            <span
                                onClick={() => {
                                    showModal("cookiePolicy");
                                }}
                            >
                                {t("profile.signUp.title.cookiePolicy")}
                            </span>
                            .
                        </p>
                        <button
                            className={classnames("button-primary", {
                                "button-primary--disabled": disabled,
                            })}
                            onClick={submit}
                            disabled={disabled}
                        >
                            {t("profile.signUp.button")}
                        </button>
                    </div>
                )}
            </PageWrapper>
        </div>
    );
};

export default UpdateProfile;
