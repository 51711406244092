import React, { useState, useEffect } from 'react'
import PageWrapper from '@components/PageWrapper';
import { IoCheckmarkCircleSharp } from "react-icons/io5";
import { IoCloseCircleSharp } from "react-icons/io5";
import { v4 as uuid } from 'uuid';
import $ from 'jquery';
import './SI_SecondPage.css';

const SI_SecondPage = (props) => {
  const userAnswers = props.question.data.data;
  const { correctAnswers } = props;
  const [wrongAnswers, setWrongAnswers] = useState([]);
  const [rightAnswers, setRightANswers] = useState();

  useEffect(() => {
    const calculateUsersAnswers = () => {
      let tempWrongAnswers = [];
      let tempRightAnswers = 0;
      Object.entries(userAnswers).forEach((entry, index) => {
        if (Number(entry[1]) !== Number(correctAnswers[index].correctVal)) {
  
          //user picked wrong choice for this question
          let correctAnswerText = correctAnswers[index].choices.filter(choice => choice.value === correctAnswers[index].correctVal);
  
          let usersAnswerText = correctAnswers[index].choices.filter(choice => Number(choice.value) === Number(entry[1]));
  
          tempWrongAnswers.push({
            rightAnswer: correctAnswerText[0]?.text,
            usersAnswer: usersAnswerText[0]?.text,
            answerDescription: correctAnswers[index]?.text,
            references: correctAnswers[index]?.reference
          })
        } else {
          //user picked the right answer for this question
          tempRightAnswers++;
        }
  
        setWrongAnswers(tempWrongAnswers);
        setRightANswers(tempRightAnswers);
      })
    }

    calculateUsersAnswers();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    $('.survey-component__survey .sv_main').addClass('overWriteSurvey');
    $('.survey-component__survey .sv_body').css('line-height', 'normal');

    return () => {
      $('.survey-component__survey .sv_main').removeClass('overWriteSurvey');
    }
  }, [])

  
  return (
    <div className="SI_SecondPage-container">
      <PageWrapper>
        <div className="right-answers-container">
          {
            rightAnswers >= 8 ? <p>You got <span>{rightAnswers}</span>/<span style={{'fontWeight': 600, 'color': 'black'}}>{correctAnswers.length}</span> correct answers. Well done! You’re a data champion.</p> : <p>You got <span>{rightAnswers}</span>/<span style={{'fontWeight': 600, 'color': 'black'}}>{correctAnswers.length}</span> correct answers. Check your answers and try again until you can ace this quick review. That way you can feel confidently prepared with a solid foundation of the data.</p>
          }
          {
            rightAnswers === 10
              ? '' 
              : rightAnswers === 9 
                ?<h1>Here is the <span style={{'color': '#31a368'}}>correct</span> answer.</h1>
                :<h1>Here are the <span style={{'color': '#31a368'}}>correct</span> answers.</h1>
          } 
        </div>

        <div className="wrong-answers-container">
          {
            wrongAnswers.map(answer => {
              const keyId = uuid();
              return(
                <AnswerDescriptionComponent 
                  key={keyId}
                  rightAnswer={answer.rightAnswer}
                  usersAnswer={answer.usersAnswer}
                  answerDescription={answer.answerDescription}
                  references={answer.references}
                />
              )
            })
          }
        </div>

      </PageWrapper>
    </div>
  )
}

export default SI_SecondPage


//create cards component for this page
const AnswerDescriptionComponent = ({rightAnswer, usersAnswer, answerDescription, references}) => {

  return(
    <div className="answer-description-container">
      <div className="right-answer-container">
        <div>
          <IoCheckmarkCircleSharp />
        </div>
        <h1>{rightAnswer}</h1>
      </div>
      <div className="wrong-answer-container">
        <div>
          <IoCloseCircleSharp />
        </div>
        <h1>{usersAnswer}</h1>
      </div>

      <p>{answerDescription}</p>

      <div className="references-container">
        <h1>References:</h1>
        <ul>
          {
            references.map(ref => {
              const keyId = uuid();
              return (
                <li key={keyId}>
                  {ref}
                </li>
              )
            })
          }
        </ul>
      </div>
    </div>
  )
}
