import React, { useState } from "react";
import "./style.css";
import ReactCardFlip from "react-card-flip";
import { analyticsLogEvent } from "@components/Analytics";

const FlipCards = ({ children }) => {
    const [isFlipped, setIsFlipped] = useState(false);

    const renderChildren = () => {
        // group by two since reactcardflip needs two children
        const child = React.Children.toArray(children).reduce(
            (items, key, index) => {
                return (
                    (index % 2 === 0
                        ? items.push([key])
                        : items[items.length - 1].push(key)) && items
                );
            },
            []
        );
        
        return child.map((c, i) => (
            <div key={i} onClick={() => analyticsLogEvent('card_flipped', {})}>
                <ReactCardFlip
                    cardStyles={{
                        front: {
                            transformStyle: "initial",
                            zIndex: isFlipped ? -1 : "auto",
                        },
                        back: {
                            transformStyle: "initial",
                            zIndex: !isFlipped ? -1 : "auto",
                        },
                    }}
                    flipDirection="vertical"
                    isFlipped={isFlipped}
                >
                    {c}
                </ReactCardFlip>
            </div>
        ));
    };

    return (
        <div className="flip-card" onClick={() => setIsFlipped(!isFlipped)}>
            {renderChildren()}
        </div>
    );
};

export default FlipCards;
