import React from "react";
import "./style.css";
import cx from "classnames";

import { useSelector } from "react-redux";

const PageWrapper = ({ children, className, ...rest }) => {
    const player = useSelector((state) => state.app.player);

    return (
        <div
            {...rest}
            className={cx(
                "page-wrapper",
                className && `page-wrapper--${className}`,
                {
                    "page-wrapper--player-is-visible":
                        player.isVisible && player.id,
                }
            )}
        >
            {children}
        </div>
    );
};

export default PageWrapper;
