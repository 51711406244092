import React from "react";
import "./style.css";
import Video from "@components/Video";

const Introduction = ({ children }) => {
    const renderChildren = () => {
        const elements = React.Children.toArray(children);

        if (elements[elements.length - 1].type === "iframe") {
            return (
                <div className="introduction-inner__content introduction-inner__content--video">
                    {elements.map((el, i) => {
                        if (el.type === "iframe") {
                            el = <Video key={`video-${i}`}>{el}</Video>;
                        }
                        return el;
                    })}
                </div>
            );
        } else {
            return (
                <div className="introduction-inner__content">{elements}</div>
            );
        }
    };

    return <div className="introduction-inner">{renderChildren()}</div>;
};

export default Introduction;
