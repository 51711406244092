const useCalculatePWCResults = () => {

  const calculateResults = (survey, scope = null) => {
    let userResults = {
      x: {
        value: 0,
        questionCount: 0
      },
      y: {
        value: 0,
        questionCount: 0
      }
    }

    let pages;
    let data;

    //survey objects are different so we set a scope to know where they are being called from
    if (scope) {
      pages = survey?.pages;
      data = survey?.data;
    } else {
      data = survey?.data?.data;
      pages = survey?.data?.pages;
    }


    pages?.forEach(page => {
      if (page.elements[0] && page.elements[0].axis) {
        userResults[`${page.elements[0].axis}`].value += Number(data[`${page.elements[0].name}`] || 0);
        userResults[`${page.elements[0].axis}`].questionCount += 1;
      }
    })

    const finalResults = {
      x: userResults.x.value / userResults.x.questionCount,
      y: userResults.y.value / userResults.y.questionCount
    }

    return finalResults
  }

  return calculateResults;

}

export default useCalculatePWCResults;