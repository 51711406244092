import { useState } from "react";

const usePagination = (data, itemsPerPage, page) => {
    const [currentPage, setCurrentPage] = useState(page || 0);
    const maxPages =
        data && data.length > 0 ? Math.ceil(data.length / itemsPerPage) : 0;

    const currentData = () => {
        const start = (currentPage - 1) * itemsPerPage;
        const end = start + itemsPerPage;
        return data.slice(start, end);
    };

    const next = () => {
        setCurrentPage((currentPage) =>
            Math.min(currentPage + 1, maxPages - 1)
        );
    };

    const prev = () => {
        setCurrentPage((currentPage) => Math.max(currentPage - 1, 0));
    };

    return { next, prev, currentPage, currentData, maxPages };
};

export default usePagination;
